import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

import { passwordResetHandler } from "../utils/authHelper";
import BtnLoader from "./btnLoader";

const ForgoPassword = ({ onClose, open }) => {
  const [email, setEmail] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  return (
    <>
      <div
        className={`fixed h-[80vh]
        ${open ? `top-0 duration-300` : "top-[-100%] duration-300"} 
          mx-auto left-1 right-1 flex items-center justify-center z-[9999]`}
      >
        <div
          className="sm:w-[430px] w-[90%] bg-white shadow-2xl rounded-xl flex relative 
          items-center flex-col pb-4"
        >
          <AiOutlineClose
            className="absolute cursor-pointer right-4 top-3 text-[20px]"
            onClick={onClose}
          />
          <div className="py-2 font-semibold my-2 text-[15px]">
            Reset Password
          </div>
          <div className="py-2 flex items-center w-80 border-b-2">
            <input
              type="text"
              className="py-1 outline-none text-center"
              placeholder="Email"
              style={{ width: "100%" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <span className="py-2 text-[13px] px-6 text-center">
            A reset password email will be sent to the provided email address.
          </span>
          <div
            className="border mt-[20px] w-[260px] cursor-pointer border-gray-400
           hover:bg-gray-200 duration-200 sm:w-[80%] my-2"
            onClick={() =>
              passwordResetHandler(email, showLoader, setShowLoader, onClose)
            }
          >
            <div className="flex items-center justify-center py-2">
              {showLoader ? <BtnLoader stroke="black" /> : "Send"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgoPassword;
