import React, { useState } from "react";
import { RiMenu4Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { FaWindowClose } from "react-icons/fa";

import LoginPopup from "./loginPopup";
import { useUserContext } from "../context/user";
import Drawer from "./drawer";
import Guide from "../screens/homeLogout/guide";
import logo from "../images/logoNew.png";

const Header = ({ hideButtons = false, signInEvent = () => {} }) => {
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showGuideModal, setShowGuideModal] = useState(false);
  const [popupVisible, setPopupVisible] = useState(0);
  const { loggedIn } = useUserContext();
  const navigate = useNavigate();

  return (
    <>
      <header className="fixed top-0 left-0 right-0 z-50 bg-gray-800 text-white px-2 py-1 md:px-8 flex justify-between items-center">
        {!hideButtons && (
          <RiMenu4Fill
            className="h-6 w-6 hover:cursor-pointer"
            onClick={() => setPopupVisible(3)}
          />
        )}

        <div className="flex flex-row items-center justify-center flex-grow cursor-pointer">
          <img
            src={logo}
            width={200}
            height={400}
            className="mx-auto bg h-[50px]"
            onClick={() => navigate("/")}
          />
        </div>

        {hideButtons ? null : loggedIn ? (
          <button
            className="border w-18 md:w-24 border-white rounded-md sm:px-4 px-3 py-1 text-white hover:bg-white hover:text-gray-800"
            onClick={() => setShowGuideModal(true)}
          >
            Guide
          </button>
        ) : (
          <button
            className="border w-18 md:w-24 border-white rounded-md sm:px-4 px-2 py-1 text-white hover:bg-white hover:text-gray-800"
            onClick={() => {
              signInEvent();
              setShowLoginPopup(true);
            }}
          >
            Sign In
          </button>
        )}
      </header>
      {showLoginPopup && (
        <LoginPopup
          onClose={() => setShowLoginPopup(false)}
          open={showLoginPopup}
        />
      )}
      {popupVisible === 3 && (
        <div
          className="w-full h-full fixed top-0 right-0 bg-black opacity-50 z-50"
          onClick={() => setPopupVisible(0)}
        ></div>
      )}

      <Drawer open={popupVisible === 3} onClose={() => setPopupVisible(0)} />

      {showGuideModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-y-auto sm:pt-24">
          <div className="rounded-lg shadow-lg sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-3/4 h-[100vh] bg-white overflow-y-auto relative ">
            <Guide />
            <FaWindowClose
              className="absolute sm:top-4 top-8 sm:right-38 right-10 text-4xl sm:text-3xl font-bold text-red-600 hover:text-gray-700 z-10"
              onClick={() => setShowGuideModal(false)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
