import React from "react";

const PlayersInfo = ({ playerData, teamName, teamFlag, teamUid }) => {
  return (
    <div className="w-1/2 bg-white shadow-md rounded-lg overflow-hidden mx-2">
      <div className="p-2 bg-gray-500 text-white flex items-center">
        <h2 className="text-2xl font-bold">{teamName}</h2>
        <img
          src={teamFlag}
          alt="Home Team Flag"
          className="ml-2 w-12 h-12 object-cover rounded-full border-2 border-blue-500"
        />
      </div>
      <div className="p-4">
        {playerData
          .filter((player) => player.team_uid === teamUid)
          .map((player) => (
            <div key={player.player_id}>{player.nick_name}</div>
          ))}
      </div>
    </div>
  );
};

export default PlayersInfo;
