import {
  people01,
  people02,
  people03,
  //   facebook,
  //   instagram,
  //   linkedin,
  //   twitter,
  //   airbnb,
  //   binance,
  //   coinbase,
  //   dropbox,
  ar,
  bat,
  bow,
  wk,
  del,
  stars,
  locks,
  send,
  shield,
  star,
} from "../assets";

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Instant Team Creation",
    content:
      "Generate 10 teams in just 10 seconds, tailored by our advanced algorithms.",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Strategic Player Insights",
    content:
      "Access detailed comparisons and stats for key players to help you choose the best performers for your teams.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Comprehensive Player Stats",
    content:
      "Explore in-depth statistics for every player to make informed decisions and build stronger teams.",
  },
];
export const action = [
  {
    id: "feature-1",
    icon: locks,
    title: "Sure someone will perform",
    content: "Lock them. They will always be in lineup.",
  },
  {
    id: "feature-2",
    icon: stars,
    title: "Might Perform",
    content: "Add to favorites. They will be preferred over normal players",
  },
  {
    id: "feature-3",
    icon: del,
    title: "Doubtful?",
    content: "Delete the players you dont want in your lineup.",
  },
];
export const players = [
  {
    id: "feature-1",
    icon: bat,
    title: "Batting Pitch?",
    content: "Increase minimum batsman count.",
  },
  {
    id: "feature-2",
    icon: bow,
    title: "Bowling Pitch?",
    content: "Increase bowler count.",
  },
  {
    id: "feature-3",
    icon: ar,
    title: "All-Rounder",
    content: "Add more all-rounders for flexibility.",
  },
  {
    id: "feature-4",
    icon: wk,
    title: "Wicket Keeper",
    content: "Too many skilled WK batsman? Increase this.",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "This website has completely transformed my fantasy cricket game! Their team-building tools and expert insights have helped me craft multiple winning teams effortlessly..",
    name: "Amit Meena",
    title: "Rajsathan",
    img: people01,
  },
  {
    id: "feedback-2",
    content:
      "I used to struggle with creating competitive teams for fantasy cricket apps, but ever since I discovered this website, that's become a thing of the past.",
    name: "Udit sharma",
    title: "Punjab",
    img: people02,
  },
  {
    id: "feedback-3",
    content:
      "I can't thank this website enough for revolutionizing my fantasy cricket experience. Their tools for creating multiple winning teams are a game-changer.",
    name: "Sunil",
    title: "Delhi",
    img: people03,
  },
];
