import React from "react";
import { MdChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { getTeamName, getWinProbability } from "../utils/helpers";
import { useBootstrapContext } from "../context/bootstrap";
import { useUserContext } from "../context/user";

const InsightMini = ({ gameData, id }) => {
  const { win_probability = {} } = gameData;
  const { teamFlags } = useBootstrapContext();

  const navigate = useNavigate();
  const { sendGAEvent } = useUserContext();

  const onClickMore = () => {
    sendGAEvent("insights_clicked", {
      matchId: gameData.season_game_uid,
      matchDate: moment(gameData.season_scheduled_date).format("lll"),
      matchName: `${gameData.home_team}-${gameData.away_team}`,
    });
    navigate(`/matchInsights/${id}`);
  };

  return (
    <div className="bg-white shadow-sm mb-4 rounded-md">
      <div className="max-w-7xl mx-auto py-1 px-2 sm:px-3 lg:px-4 flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <img
            src={teamFlags[getTeamName(gameData.home)]}
            alt="Home Team"
            className="w-10 h-10 md:w-12 sm:h-12 object-cover rounded-full"
          />
          <p className="text-sm md:text-md font-semibold">
            {getWinProbability(gameData.home_uid, win_probability)}%
          </p>
        </div>
        <h2 className="text-sm md:text-md md:text-lg lg:text-xl font-semibold text-gray-900 hidden sm:block">
          Winning Probability
        </h2>
        <div className="flex items-center space-x-2">
          <p className="text-sm md:text-md font-semibold">
            {getWinProbability(gameData.away_uid, win_probability)}%
          </p>
          <img
            src={teamFlags[getTeamName(gameData.away)]}
            alt="Away Team"
            className="w-10 h-10 md:w-12 sm:h-12 object-cover rounded-full"
          />
        </div>
        <button
          type="button"
          className="ml-2 sm:ml-4 inline-flex items-center px-3 py-1 sm:px-4 sm:py-2 border border-transparent text-sm sm:text-sm font-medium rounded-md text-blue-600 hover:text-blue-800 bg-transparent hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={onClickMore}
        >
          More Insights <MdChevronRight className="-mr-1 h-5 w-5" />
        </button>
      </div>
    </div>
  );
};

export default InsightMini;
