import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useUserContext } from "../context/user";
import PageLoader from "../components/pageLoader";
import Header from "../components/header";

const AdminRoute = () => {
  const { isAdmin, user } = useUserContext();
  const location = useLocation();

  if (!user)
    return (
      <div>
        <Header />
        <PageLoader />
      </div>
    );

  if (!isAdmin) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default AdminRoute;
