import React, { useEffect, useState } from "react";
import { deleteDoc, doc, getDoc, writeBatch } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";

import { insightDataSanitizer } from "../../../utils/helpers";
import Header from "../../../components/header";
import InfoInput from "../../../components/infoInput";
import { firestore } from "../../../firebase";
import BtnLoader from "../../../components/btnLoader";
import InsightInfo from "../../../components/insightInfo";

const UploadMatchInsights = () => {
  const [insightData, setInsightData] = useState({});
  const [inputInsightData, setInputInsightData] = useState("");
  const [updating, setUpdating] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const insightDocRef = doc(firestore, "Insights", id);
      const insightDocSnap = await getDoc(insightDocRef);

      if (insightDocSnap.exists()) {
        const data = insightDocSnap.data();
        setInsightData(data);
        setUpdating(true);
      }
    } catch (err) {
      alert("Error");
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (id) getData();
  }, [id]);

  const handleInsightSubmit = () => {
    try {
      setInsightData(insightDataSanitizer(inputInsightData));
    } catch (error) {
      console.log(error);
      alert("Invalid JSON data");
    }
  };

  const onSave = async () => {
    if (showLoader) return;
    if (!Object.keys(insightData).length) return alert("Fill complete data");
    setShowLoader(true);
    try {
      const insightDocRef = doc(firestore, "Insights", id);
      const matchDocRef = doc(firestore, "Matches", id);

      const batch = writeBatch(firestore);

      if (updating) {
        batch.update(insightDocRef, insightData);
      } else {
        batch.set(insightDocRef, insightData);
      }
      batch.update(matchDocRef, { insightExists: true });
      await batch.commit();

      alert("Uploaded");
      navigate("/admin");
    } catch (err) {
      console.log("err", err);
      alert("Error");
    } finally {
      setShowLoader(false);
    }
  };

  const onDelete = async () => {
    if (showDeleteLoader) return;
    setShowDeleteLoader(true);
    try {
      const insightDataRef = doc(firestore, "Insights", id);
      await deleteDoc(insightDataRef);

      alert("Deleted");
      navigate("/admin");
    } catch (err) {
      console.log("err", err);
      alert("Error");
    } finally {
      setShowDeleteLoader(false);
    }
  };

  return (
    <div>
      <Header />
      <div className="mt-16 text-center" />
      <div className="flex p-4">
        <InfoInput
          value={inputInsightData}
          setInputData={setInputInsightData}
          buttonText="Format Match Data"
          onPress={handleInsightSubmit}
        />
        {!!Object.keys(insightData).length && (
          <InsightInfo
            insightData={insightData}
            gameData={insightData}
            teamName={insightData.home_team}
            teamFlag={insightData.home_flag}
            customClasses="px-4"
          />
        )}
      </div>

      {!!Object.keys(insightData).length && (
        <div className="text-center mt-4 mb-12">
          <button
            className="mt-2 px-8 py-2 bg-green-800 text-white rounded"
            onClick={onSave}
          >
            {showLoader ? <BtnLoader /> : "Upload Data"}
          </button>
        </div>
      )}
    </div>
  );
};

export default UploadMatchInsights;
