import React from "react";
import { action } from "../constants/index";
import styles, { layout } from "../screens/homeLogout/style";

const InfoCard = ({ icon, title, content, index }) => (
  <div
    className={`flex flex-row py-4 rounded-[20px] w-3/4 ${
      index !== action.length - 1 ? "mb-6" : "mb-0"
    } action-card items-center`}
  >
    <div
      className={`w-[64px] h-[64px] rounded-full bg-dimBlue ${styles.flexCenter}`}
    >
      <img src={icon} alt="icon" className="w-[50%] h-[50%] object-contain" />
    </div>
    <div className="flex-1 flex flex-col ml-3 ">
      <h4 className="font-poppins font-semibold text-black text-[18px] leading-[23px] mb-1">
        {title}
      </h4>
      <p className="font-poppins font-normal text-dimWhite text-[16px] leading-[24px] ">
        {content}
      </p>
    </div>
  </div>
);

const ActionInfoModal = () => {
  return (
    <div className={`${layout.sectionImg} flex-col items-center `}>
      {action.map((action, index) => (
        <InfoCard key={action.id} {...action} index={index} />
      ))}
    </div>
  );
};

export default ActionInfoModal;
