import React from "react";
import styles from "./style";
import logo from "../../images/2.png";

const Banner = () => (
  <section id="home" className={`py-8 md:py-8 flex md:flex-row flex-col`}>
    <div
      className={`flex-1 ${styles.flexStart} flex-col sm:px-16 px-6 items-center`}
    >
      <div className="flex flex-row justify-between items-center w-full">
        <h1 className="flex-1 font-poppins font-semibold ss:text-[72px] text-[52px] text-black ss:leading-[100px] leading-[60px] text-center">
          AI Generated Teams
        </h1>
      </div>
      <p className={`${styles.paragraph} max-w-[470px] mt-5 text-center`}>
        Dream Lineup uses past game data to create AI-generated teams, helping
        you excel in fantasy cricket.
      </p>
    </div>
    <div className={`flex flex-1 relative ${styles.flex}`}>
      <div className="w-[100%] h-[100%] flex items-center justify-center">
        <img
          src={logo}
          alt=""
          className="h-[95vw] w-[95vw] max-h-[500px] max-w-[500px] relative z-[5]"
        />
      </div>
    </div>
  </section>
);

export default Banner;
