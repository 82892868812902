import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useUserContext } from "../context/user";
import Header from "../components/header";
import PageLoader from "../components/pageLoader";

const PrivateRoute = () => {
  const { loggedIn, user } = useUserContext();
  const location = useLocation();

  if (loggedIn === false) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  if (!user)
    return (
      <div>
        <Header />
        <PageLoader />
      </div>
    );

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default PrivateRoute;
