import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import Header from "../../components/header";
import { firestore } from "../../firebase";
import Banner from "./banner";
import CTA from "./cts";
import Footer from "./footer";
import Testimonials from "./testimonials";
import Guide from "./guide";
import { useUserContext } from "../../context/user";
import { useLocation, useNavigate } from "react-router-dom";
import PageLoader from "../../components/pageLoader";
import HomeInsights from "./homeInsights";

const HomeLogout = () => {
  const [gameData, setGameData] = useState([]);
  const [insightData, setInsightData] = useState([]);
  const [tick, setTick] = useState(0);

  const maxDocLimit = 4;
  const { sendGAEvent, loggedIn } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async () => {
    try {
      console.log("1");
      var eventQuery = query(
        collection(firestore, "Matches"),
        where("season_scheduled_date", ">", moment().startOf("day").valueOf()),
        orderBy("season_scheduled_date", "asc"),
        limit(maxDocLimit)
      );
      console.log("2");
      const snap = await getDocs(eventQuery);
      console.log("3", snap.docs.length);
      if (snap.docs.length) {
        const newData = snap.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
          };
        });
        setGameData(newData);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    fetchData();
    setTimeout(() => {
      sendGAEvent(loggedIn ? "home_visit_logged_in" : "home_visit_logged_out");
    }, 2000);
  }, []);

  const fetchMatchInsightData = async (match) => {
    try {
      const docRef = doc(firestore, "Insights", match.id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        var tempArr = insightData;
        const data = docSnap.data();
        tempArr.push({ match, insights: data });
        setInsightData(tempArr);
        setTick((i) => i + 1);
      }
    } catch (err) {
      alert("Error");
      console.log("err", err);
    }
  };

  const fetchInsights = async () => {
    for (const match of gameData) await fetchMatchInsightData(match);
  };

  useEffect(() => {
    if (gameData.length && !insightData.length) fetchInsights();
  }, [gameData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const onViewMore = (item) => {
    window.scrollTo(0, 0);
    const { match } = item;
    sendGAEvent(
      loggedIn
        ? "insight_click_home_logged_in"
        : "insight_click_home_logged_out",
      {
        matchId: match.season_game_uid,
        matchDate: moment(match.season_scheduled_date).format("lll"),
        matchName: `${match.home_team}-${match.away_team}`,
      }
    );
    navigate(`/matchInsights/${match.id}`);
  };

  return (
    <div className="overflow-y-auto">
      <Header signInEvent={() => sendGAEvent("home_signin_attempt")} />
      {gameData?.length ? (
        <>
          <div className="pt-14 md:pt-16" />
          <div className="block md:hidden">
            <HomeInsights insightData={insightData} onViewMore={onViewMore} />
            <CTA gameData={gameData} />
            <Banner />
          </div>

          <div className="hidden md:block">
            <Banner />
            <CTA gameData={gameData} />
            <HomeInsights insightData={insightData} onViewMore={onViewMore} />
          </div>
          <Guide />
          <Footer />
        </>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

export default HomeLogout;
