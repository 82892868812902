import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCredential,
  OAuthProvider,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase";

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");
appleProvider.addScope("email");
appleProvider.addScope("name");

export const loginHandler = async (
  email,
  password,
  showLoader,
  setShowLoader,
  onSuccess = () => {}
) => {
  if (showLoader) return;
  if (!email) alert("Please Enter Email");
  else if (!password) alert("Please Enter Password");
  else {
    setShowLoader(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(() => onSuccess())
      .catch((error) => {
        console.log("err", error.code);
        if (error.code === "auth/wrong-password") alert("Invalid password!");
        else if (error.code === "auth/invalid-email")
          alert("That email address is invalid!");
        else if (error.code === "auth/user-not-found")
          alert("User does not exist.");
        else alert(error.message);
      })
      .finally(() => setShowLoader(false));
  }
};

export const signUpHandler = async (
  email,
  password,
  cnfPass,
  showLoader,
  setShowLoader,
  onSuccess = () => {}
) => {
  if (showLoader) return;
  if (!email) alert("Please Enter Email");
  else if (!password) alert("Enter Password");
  else if (password.length < 6)
    alert("Password should be at least 6 characters!");
  else if (password !== cnfPass) alert("Passwords do not match");
  else {
    setShowLoader(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then(() => onSuccess())
      .catch((error) => {
        console.log("err", error);
        if (isEmailUsed)
          alert(
            `Account already Exists! Use ${
              authMethod.split(".")[0]
            } method to signin`
          );
        if (error.code === "auth/email-already-in-use") {
          alert("Account already exists! Please use a different email.");
        } else if (error.code === "auth/wrong-password")
          alert("Invalid password!");
        else if (error.code === "auth/invalid-email")
          alert("That email address is invalid!");
        else if (error.code === "auth/user-not-found")
          alert("User does not exist.");
        else alert(error.message);
      })
      .finally(() => setShowLoader(false));
  }
};

export const googleSignInHandler = async (
  showLoader,
  setShowLoader,
  callback = () => {}
) => {
  try {
    if (showLoader) return;
    setShowLoader(true);
    const res = await signInWithPopup(auth, googleProvider);
    const googleCredential = GoogleAuthProvider.credentialFromResult(res);
    await signInWithCredential(auth, googleCredential);
    callback();
  } catch (err) {
    if (err?.code == "auth/multi-factor-auth-required")
      alert("Email already used. Please use another email address.");
    else alert("Something went wrong! Please try again later.");
    console.log("error", err);
  } finally {
    setShowLoader(false);
  }
};

export const passwordResetHandler = async (
  email,
  showLoader,
  setShowLoader,
  callback = () => {}
) => {
  if (showLoader) return;
  if (!email) return alert("Please Enter Email");
  try {
    setShowLoader(true);
    await sendPasswordResetEmail(auth, email.trim());
    callback();
    alert("Password reset email sent successfully");
  } catch (error) {
    console.log("error", error);
    if (error.code == "auth/user-not-found")
      alert("Invalid email. User not found.");
    else alert("Something went wrong! Please try again later.");
  } finally {
    setShowLoader(false);
  }
};
