import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDwVX4M84HmgRCVrXOkgP67ZCNWr5NxEio",
  authDomain: "cricketlineup-c54a1.firebaseapp.com",
  projectId: "cricketlineup-c54a1",
  storageBucket: "cricketlineup-c54a1.appspot.com",
  messagingSenderId: "898812517645",
  appId: "1:898812517645:web:f3f233942d94f59f956444",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);
