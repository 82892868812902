import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";

import Header from "../../components/header";
import GameCard from "../../components/gameCard";
import { firestore } from "../../firebase";
import PageLoader from "../../components/pageLoader";

const UpcomingMatches = () => {
  const [gameData, setGameData] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [page, setPage] = useState(0);
  const [dataToShow, setDataToShow] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const maxDocLimit = 6;
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setShowLoader(true);
      var eventQuery = query(
        collection(firestore, "Matches"),
        where("season_scheduled_date", ">", moment().startOf("day").valueOf()),
        orderBy("season_scheduled_date", "asc"),
        limit(maxDocLimit)
      );
      const snap = await getDocs(eventQuery);
      if (snap.docs.length) {
        const newData = snap.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
          };
        });
        setGameData(newData);
        if (snap.docs.length == maxDocLimit)
          setLastDoc(snap.docs[snap.docs.length - 1]);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchMoreData = async () => {
    if (gameData.length > page + maxDocLimit) setPage(page + maxDocLimit);
    else {
      try {
        setShowLoader(true);
        var gameQuery = query(
          collection(firestore, "Matches"),
          where(
            "season_scheduled_date",
            ">",
            moment().startOf("day").valueOf()
          ),
          orderBy("season_scheduled_date", "asc"),
          startAfter(lastDoc),
          limit(maxDocLimit)
        );
        var snap = await getDocs(gameQuery);

        if (snap.docs.length) {
          const newData = snap.docs.map((doc) => {
            const data = doc.data();
            return {
              ...data,
              id: doc.id,
            };
          });
          setGameData([...gameData, ...newData]);
          setPage(page + maxDocLimit);
          if (snap.docs.length == maxDocLimit)
            setLastDoc(snap.docs[snap.docs.length - 1]);
          else setLastDoc(null);
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        setShowLoader(false);
      }
    }
  };

  useEffect(() => {
    if (!gameData.length) return;
    var data = gameData.slice(page, page + maxDocLimit);
    setDataToShow(data);
  }, [gameData, page, lastDoc]);

  return (
    <div>
      <Header />
      {showLoader ? (
        <PageLoader />
      ) : (
        <>
          <div className="flex justify-center mt-12">
            <button
              onClick={() => navigate("upload")}
              className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-6 mt-4 rounded"
            >
              Create New Match
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-4 lg:px-12 py-4">
            {dataToShow.map((game) => (
              <GameCard
                key={game.season_game_uid}
                game={game}
                showEditButton
                showInsightButton
                onEditPress={() => navigate(`upload/${game.id}`)}
                onInsightPress={() => navigate(`insight/${game.id}`)}
              />
            ))} 
          </div>
          <div className="flex justify-center mb-4">
            {!!page && (
              <button
                className="bg-gray-600 hover:bg-gray-800 py-2 px-4 rounded mx-2 text-white"
                onClick={() => setPage(page - maxDocLimit)}
              >
                Prev
              </button>
            )}
            {(!!lastDoc || gameData.length > page + maxDocLimit) && (
              <button
                className="bg-gray-600 hover:bg-gray-800 py-2 px-4 rounded mx-2 text-white"
                onClick={fetchMoreData}
              >
                Next
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UpcomingMatches;
