import React, { useEffect, useState } from "react";
import { CiCamera } from "react-icons/ci";
// import "react-calendar/dist/Calendar.css";
import { IoIosCloseCircle } from "react-icons/io";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";

import { formatDate, validatephone } from "../../utils/validation";
import { firestore, storage } from "../../firebase";
import BtnLoader from "../../components/btnLoader";
import { useUserContext } from "../../context/user";
import Header from "../../components/header";

const emptyData = {
  phone: "",
  name: "",
  birthDate: "",
  gender: "",
};

const Profile = (e) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [formData, setFormData] = useState({ ...emptyData });
  const [errors, setErrors] = useState({});
  const [showLoader, setShowLoader] = useState(false);

  const { user } = useUserContext();

  useEffect(() => {
    setFormData({
      name: user?.name || emptyData.name,
      phone: user?.phone || emptyData.phone,
      birthDate: user?.birthDate
        ? formatDate(new Date(user.birthDate))
        : emptyData.birthDate,
      gender: user?.gender || emptyData.gender,
    });
    setImageUrl(user?.profileImage || "");
  }, [user]);

  const handleSubmit = async () => {
    if (showLoader) return;
    const newErrors = {};
    if (formData.phone && !validatephone(formData.phone)) {
      newErrors.phone = "Invalid phone number";
    }

    if (Object.keys(newErrors).length == 0) {
      setErrors({});
      const { userId } = user;
      const docRef = doc(firestore, "Users", userId);
      try {
        setShowLoader(true);
        var profileImage = selectedFile ? "" : imageUrl;

        if (selectedFile) {
          var storageRef = ref(
            storage,
            `/images/profilePictures/${userId}/${selectedFile.name}`
          );
          await uploadBytes(storageRef, selectedFile);
          profileImage = await getDownloadURL(storageRef);
        }

        await updateDoc(docRef, {
          ...formData,
          birthDate: formData.birthDate
            ? new Date(formData.birthDate).toISOString()
            : "",
          profileImage,
        });
        alert("Profile Updated!");
      } catch (err) {
        console.log("err", err);
        alert("Error! Please try again later.");
      } finally {
        setShowLoader(false);
      }
    } else setErrors(newErrors);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (event) => {
    const file = event?.target?.files?.[0]; // Using optional chaining to avoid undefined errors
    if (file) {
      setSelectedFile(file);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const handleGenderSelect = (val) => {
    setFormData({
      ...formData,
      gender: val == formData.gender ? "" : val,
    });
  };

  return (
    <>
      <Header />
      <div className="w-[100%] flex justify-center bg-[#F2F5F9] sm:bg-red mt-14 h-[100vh]">
        <div className="w-[708px] ">
          <form>
            <div className="bg-gradient-to-r from-[#2B3148] via-[#883F55] to-[#EB4E62]">
              <div
                className="w-full relative h-[6rem] border-tl-4 border-tr-4 
              bg-gradient-to-r from-[#2B3148] via-[#883F55] to-[#EB4E62] px-10"
              >
                {/*rounded profile div */}
                <div className="flex text-center items-center gap-6">
                  <div
                    className={`bg-white border-2 flex justify-center 
                ${imageUrl ? "pt-0" : "pt-5"}
                  mt-4 w-[100px] h-[100px] 
                  rounded-full relative`}
                  >
                    {imageUrl ? (
                      <div className="rounded-full overflow-hidden">
                        <img
                          src={imageUrl}
                          alt="Selected"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                        <IoIosCloseCircle
                          className="text-[24px] cursor-pointer text-red-100 "
                          style={{
                            position: "absolute",
                            top: -2.5,
                            right: -2.5,
                            zIndex: 100,
                          }}
                          onClick={() => {
                            setSelectedFile(null);
                            setImageUrl("");
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <input
                          name="file"
                          type="file"
                          placeholder="+Add"
                          id="fileInput"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                        <label htmlFor="fileInput" className="cursor-pointer">
                          <div className="h-fit">
                            <span className="cursor-pointer">
                              <CiCamera
                                className="text-[40px] bg-grey-400"
                                onClick={handleFileChange}
                              />
                            </span>
                            <span className="text-[15px]">
                              {selectedFile
                                ? `Selected File: ${selectedFile.name}`
                                : "+Add"}
                            </span>
                          </div>
                        </label>
                      </>
                    )}
                  </div>

                  <div className="text-white  h-fit text-[21px] font-[700]">
                    {`Hi, ${user.name || "Guest"}`}
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full  pt-[36px] py-[64px] bg-white px-[10px] sm:px-[36px]">
              <div className=" ">
                <span className="text-[24px] font-[500]">Account details</span>
                <div className=" flex items-center justify-between px-5 mt-4 ">
                  <span className="w-[34%] sm:w-[25%] text-[14px] sm:text-[16px]">
                    Email Address
                  </span>
                  <div className="flex justify-between w-[65%]  items-center">
                    <input
                      name="email"
                      placeholder="Email"
                      required
                      value={user.email}
                      readOnly
                      className="outline-none rounded-md p-1  sm:px-2 sm:py-2  w-[75%]"
                    />
                  </div>
                </div>
                <div className=" flex mt-4 items-center justify-between px-5 ">
                  <span className="w-[34%]  sm:w-[25%] text-[14px] sm:text-[16px]">
                    Mobile Number
                  </span>
                  <div className="flex  justify-between w-[65%] items-center">
                    <input
                      name="phone"
                      placeholder="Phone"
                      value={formData.phone}
                      onChange={handleChange}
                      type="phone"
                      className="border  border-grey-500 outline-none 
                    rounded-md p-1 sm:px-2 sm:py-2  w-[75%]"
                    />
                  </div>
                </div>
                {!!errors?.phone && (
                  <p className="text-red-500 font-thin text-sm ml-56 mt-1">
                    {errors.phone}
                  </p>
                )}
              </div>
            </div>
            <div className="w-full mt-4 py-10  bg-white px-[10px] sm:px-[36px]">
              <div className="">
                <span className="text-[24px] font-[500]">Personal details</span>
                <div className="px-5">
                  <div className=" items-center gap-6 flex mt-6">
                    <span className="w-[34%] text-[14px] sm:text-[16px]">
                      Name
                    </span>
                    <input
                      placeholder="Name"
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="border border-grey-400 w-[70%]
                     rounded-md px-2 py-[6px] sm:py-2"
                    />
                  </div>
                  <div className="gap-6 items-center flex mt-6">
                    <span className="w-[34%] text-[14px] sm:text-[16px]">
                      Birthday
                    </span>
                    <input
                      type="date"
                      name="birthDate"
                      value={formData.birthDate}
                      onChange={handleChange}
                      className="border w-[70%] outline-none
                     border-grey-400 rounded-md px-2 py-2 "
                    />
                  </div>
                  <div className="gap-6 flex mt-6">
                    <span className="w-[34%] text-[14px] sm:text-[16px]">
                      Identity
                    </span>
                    <div className="flex gap-3  w-[70%]">
                      <input
                        type="text"
                        name="male"
                        id=""
                        style={{
                          background:
                            formData.gender == "male" ? "#1f2937" : "#fff",
                          color: formData.gender == "male" ? "#fff" : "#000",
                        }}
                        className={`border rounded-md border-gray-200 py-1
                       w-[100px] outline-none cursor-pointer text-center`}
                        value="Male"
                        readOnly
                        onClick={() => handleGenderSelect("male")}
                      />
                      <input
                        type="text"
                        name="female"
                        id=""
                        style={{
                          background:
                            formData.gender == "female" ? "#1f2937" : "#fff",
                          color: formData.gender == "female" ? "#fff" : "#000",
                        }}
                        className="border rounded-md border-gray-200 py-1
                        w-[100px] outline-none cursor-pointer text-center"
                        value="Female"
                        readOnly
                        onClick={() => handleGenderSelect("female")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <div
                  onClick={handleSubmit}
                  className="bg-gray-800 flex justify-center w-[35%] mt-8 py-[6px] 
                rounded-sm text-white cursor-pointer"
                >
                  {showLoader ? <BtnLoader /> : "Save"}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Profile;
