import React from "react";
import { FaChevronDown } from "react-icons/fa";

const ExpandableWrapper = ({ children, title, subTitle, onViewMore }) => {
  return (
    <div className="mx-auto mb-4 overflow-hidden border rounded-lg border-gray-100 shadow-md bg-gradient-to-b from-blue-100 to-violet-100 md:max-w-5xl">
      <h2 className="mt-8 text-center text-xl font-bold">Match Insights</h2>
      <h2 className="text-center text-md font-semibold text-gray-700">
        {title}
      </h2>
      <p className="text-center text-sm text-gray-600">{subTitle}</p>
      <div
        className={`max-h-[80vh] relative transition-all duration-700 ease-in-out overflow-hidden`}
      >
        {children}
        <div className="absolute inset-x-0 bottom-0 h-28 bg-gradient-to-b from-transparent to-white"></div>
      </div>
      <button
        onClick={onViewMore}
        className="flex items-center justify-center w-full py-4 text-md font-semibold text-green-600 bg-white"
      >
        View More <FaChevronDown className="ml-2" />
      </button>
    </div>
  );
};

export default ExpandableWrapper;
