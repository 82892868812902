import React from "react";
import styles from "./style";
import match from "../../assets/match.png";
import custom from "../../assets/custom.png";
import select from "../../assets/select.png";
import created from "../../assets/created.png";
import grounded from "../../assets/grounded.png";
import history from "../../assets/history.png";

const Guide = () => {
  return (
    <section
      id="guide"
      className={`${styles.flexCenter} flex-col  overflow-y-auto bg-white`}
    >
      <div className="w-full justify-between items-center flex md:flex-row flex-col sm:mb-8 mb-4 relative z-[1] sm:px-16 px-6 ">
        <h1 className="text-[6.375rem] sm:text-[8rem] sm:mr-[30px] text-red-700 ">
          6
        </h1>
        <h2
          className={`font-poppins font-semibold sm:text-[52px] text-[42px] text-black xs:leading-[76.8px] leading-[56.8px] w-full `}
        >
          Easy Steps to get <br className="sm:block hidden" /> multiple teams
        </h2>
        <div className="w-full md:mt-0 mt-6">
          <p className={`${styles.paragraph} text-left max-w-[550px]`}>
            Follow these 6 steps to get up to 10 fantasy teams for cricket to
            play on any fantasy sports platform.
          </p>
        </div>
      </div>
      <div className="flex flex-wrap justify-center max-w-[1100px] mx-auto">
        {[
          { image: match, alt: "match" },
          { image: custom, alt: "custom" },
          { image: select, alt: "select" },
          { image: created, alt: "created" },
          { image: grounded, alt: "grounded" },
          { image: history, alt: "history" },
        ].map((item, index) => (
          <div key={index} className="w-full sm:w-1/3 p-4">
            <div className="bg-white rounded-lg border border-gray-500 shadow-md overflow-hidden">
              <img src={item.image} className="w-full" alt={item.alt} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Guide;
