import React from "react";

import BtnLoader from "../../../components/btnLoader";
import "./tableFooter.css";
import { useUserContext } from "../../../context/user";
import moment from "moment";

const TableFooter = ({
  teamCount,
  setTeamCount,
  generateTeams,
  showBtnLoader,
  game,
}) => {
  const { loggedIn, sendGAEvent } = useUserContext();

  const sendEvent = () => {
    if (loggedIn)
      sendGAEvent("generate_teams_logged_in", {
        matchId: game.season_game_uid,
        matchDate: moment(game.season_scheduled_date).format("lll"),
        matchName: `${game.home_team}-${game.away_team}`,
      });
    else
      sendGAEvent("generate_teams_logged_out", {
        matchId: game.season_game_uid,
        matchDate: moment(game.season_scheduled_date).format("lll"),
        matchName: `${game.home_team}-${game.away_team}`,
      });
  };

  return (
    <div
      className="fixed bottom-0 left-0 right-0 flex justify-between items-center px-4 py-2 bg-white border border-b-0 border-gray-300 text-sm md:text-base shadow-xl rounded-2xl m-2 text-gray-800"
      style={{
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(255,255,255,0.9)",
      }}
    >
      <div className="flex items-center space-x-2 md:space-x-4">
        <span className="font-semibold">Teams:</span>
        <button
          onClick={() => setTeamCount(Math.max(1, teamCount - 1))}
          className="px-4 md:px-3 py-1 bg-gray-200 rounded-full font-semibold hover:bg-gray-300 transition duration-150 ease-in-out"
        >
          -
        </button>
        <span className="text-lg md:text-xl">{teamCount}</span>
        <button
          onClick={() => setTeamCount(Math.min(teamCount + 1, 10))}
          className="px-4 md:px-3 py-1 bg-gray-200 rounded-full font-semibold hover:bg-gray-300 transition duration-150 ease-in-out"
        >
          +
        </button>
      </div>
      <button
        onClick={() => {
          sendEvent();
          generateTeams();
        }}
        className={`flex items-center justify-center hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-500 text-white font-bold px-6 py-3 rounded-lg transition duration-150 ease-in-out scale-and-glow`}
        style={{
          boxShadow: "0 4px 8px rgba(0,0,0,0.2), 0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        {showBtnLoader ? <BtnLoader /> : `GENERATE`}
      </button>
    </div>
  );
};

export default TableFooter;
