import "./App.css";
import { BrowserRouter } from "react-router-dom";

import Root from "./routes/root";
import OutputProvider from "./context/output";
import UserProvider from "./context/user";
import BootstrapProvider from "./context/bootstrap";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/service-worker.js")
          .then((registration) => {
            console.log("Service Worker registered: ", registration);
          })
          .catch((registrationError) => {
            console.log(
              "Service Worker registration failed: ",
              registrationError
            );
          });
      });
    }
  }, []);

  return (
    <BootstrapProvider>
      <UserProvider>
        <OutputProvider>
          <BrowserRouter>
            <Root />
          </BrowserRouter>
        </OutputProvider>
      </UserProvider>
    </BootstrapProvider>
  );
}

export default App;
