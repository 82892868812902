import React, { useEffect, useState } from "react";
import TeamLayout from "../teamLayout";
import { useParams } from "react-router-dom";

import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import ToolHeader from "../../components/toolHeader";
import { firestore } from "../../firebase";
import { useUserContext } from "../../context/user";
import { useOutputContext } from "../../context/output";
import moment from "moment";
import LoginPopup from "../../components/loginPopup";

const GenereatedTeams = () => {
  const [gameData, setGameData] = useState(null);
  const [savedLineups, setSavedLineups] = useState([]);
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const { lineupOutput, setLineupOutput } = useOutputContext();
  const { userId, loggedIn, sendGAEvent, isAdmin } = useUserContext();
  const { matchid } = useParams();

  const getData = async () => {
    try {
      const gameDocRef = doc(firestore, "Matches", matchid);
      const gameDocSnap = await getDoc(gameDocRef);

      if (gameDocSnap.exists()) {
        const data = gameDocSnap.data();
        setGameData(data);
      }
    } catch (err) {
      alert("Error");
      console.log("err", err);
    }
  };

  useEffect(() => {
    getData();
  }, [matchid]);

  useEffect(() => {
    if (Object.keys(lineupOutput).length)
      localStorage.setItem(
        "savedLineups",
        JSON.stringify({ [matchid]: lineupOutput })
      );
  }, [matchid]);

  useEffect(() => {
    if (!Object.keys(lineupOutput).length) {
      const savedInStorage = localStorage.getItem("savedLineups");
      if (savedLineups && typeof savedInStorage == "string") {
        const data = JSON.parse(savedInStorage);
        if (data && data?.[matchid]) setLineupOutput(data?.[matchid]);
      }
    }
  }, [matchid]);

  const onSave = async (lineupId) => {
    if (savedLineups.includes(lineupId)) return;

    if (!savedLineups.length)
      sendGAEvent("save_lineup", {
        matchId: gameData.season_game_uid,
        matchDate: moment(gameData.season_scheduled_date).format("lll"),
        matchName: `${gameData.home_team}-${gameData.away_team}`,
      });

    setSavedLineups([...savedLineups, lineupId]);
    try {
      const docRef = doc(firestore, "SavedLineups", `${matchid}_${userId}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await updateDoc(docRef, {
          [`lineups.${lineupId}`]: lineupOutput[lineupId],
        });
      } else {
        const payload = {
          lineups: { [lineupId]: lineupOutput[lineupId] },
          match_start_time: gameData.season_scheduled_date,
          userId,
          matchId: matchid,
        };
        await setDoc(docRef, payload);
      }
    } catch (err) {
      console.log("err", err);
      setSavedLineups([...savedLineups.filter((i) => i != lineupId)]);
      alert("Error");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onInsightExport = async (lineupId) => {
    try {
      const docRef = doc(firestore, "Insights", matchid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await updateDoc(docRef, {
          lineup_players: lineupOutput[lineupId],
        });
      } else {
        const payload = {
          lineup_players: lineupOutput[lineupId],
        };
        await setDoc(docRef, payload);
      }
      alert("Exported");
    } catch (err) {
      console.log("err", err);
      alert("Error");
    }
  };

  return (
    <div className="min-h-screen ">
      <ToolHeader
        title={"Team Outputs"}
        subTitle={gameData ? `${gameData.home} vs ${gameData.away}` : ""}
      />
      <div className="flex flex-wrap justify-center gap-6 sm:gap-0 mt-20">
        {Object.keys(lineupOutput).map((id) => (
          <TeamLayout
            selectedLineup={lineupOutput[id]}
            onSave={() => {
              if (loggedIn) onSave(id);
              else setShowLoginPopup(true);
            }}
            isSaved={savedLineups.includes(id)}
            onInsightExport={() => onInsightExport(id)}
            showExportBtn={isAdmin}
          />
        ))}
      </div>
      {showLoginPopup && (
        <LoginPopup
          onClose={() => setShowLoginPopup(false)}
          open={showLoginPopup}
        />
      )}
    </div>
  );
};

export default GenereatedTeams;
