import React, { useEffect, useState } from "react";
import {
  FaSave,
  FaCheckCircle,
  FaFileExport,
  FaArrowAltCircleRight,
} from "react-icons/fa";
import { GiSpikedBat, GiBaseballGlove } from "react-icons/gi";
import { BiSolidCricketBall } from "react-icons/bi";

import pitchBg from "./assets/pitchBg.png";
import { IoTrashBin } from "react-icons/io5";
import { useBootstrapContext } from "../../context/bootstrap";
import { useUserContext } from "../../context/user";

const roleIcons = {
  Batsmen: <GiSpikedBat color="#E48F45" className="h-5 w-5" />,
  Bowlers: <BiSolidCricketBall color="#7D0A0A" className="h-4 w-4" />,
  "Wicket Keepers": <GiBaseballGlove color="#9BB0C1" className="h-5 w-5" />,
  "All Rounders": (
    <div className="flex items-center">
      <GiSpikedBat color="#E48F45" className="h-5 w-5" />
      <BiSolidCricketBall color="#7D0A0A" className="h-4 w-4" />
    </div>
  ),
};

const getTeamColor = (team_abbr) => {
  const teamColors = {
    SL: "#387ADF",
    BAN: "#74E291",
    default: "#DADFE1",
  };
  return teamColors[team_abbr] || teamColors.default;
};

function limitStringTo10(str) {
  if (str.length > 10) {
    return str.slice(0, 9) + ".";
  } else {
    return str;
  }
}

const PlayerCard = ({
  nick_name,
  team_abbr,
  power_rate,
  teamOne,
  jersey,
  designation,
}) => {
  const teamColor = React.useMemo(() => getTeamColor(team_abbr), [team_abbr]);
  const bgClass = team_abbr == teamOne ? "bg-[#00000095]" : "bg-[#FFFFFF95]";
  const textClass = team_abbr != teamOne ? "text-black" : "text-white";

  const { teamJerseys } = useBootstrapContext();

  return (
    <div className="mb-0 mx-2 lg:mx-4 min-w-16 max-w-40 overflow-hidden relative">
      {!!designation && (
        <div className="absolute top-0 right-0 bg-red-500 rounded-full w-5 h-5 flex items-center justify-center text-white text-xs">
          {designation}
        </div>
      )}
      <div className="text-center">
        <div className="mx-auto mb-0" style={{ color: teamColor }}>
          <img
            src={teamJerseys?.[team_abbr] || jersey}
            alt={team_abbr}
            className="w-8 h-8 md:w-10 md:h-10 inline-block"
          />
        </div>
        <div className={`${bgClass} rounded-lg px-2`}>
          <div className={`font-semibold lg:text-base text-sm ${textClass}`}>
            {limitStringTo10(nick_name)}
          </div>
          <div className={`text-sm ${textClass}`}>{Math.floor(power_rate)}</div>
        </div>
      </div>
    </div>
  );
};

const PlayerList = ({ players, title, teamOne }) => {
  const positionIcon = React.useMemo(() => roleIcons[title] || "❓", [title]);

  return (
    <div className="w-full text-center mb-4 lg:mb-0">
      <div className="flex justify-center items-center my-1 lg:my-2">
        <div className="text-gray-300 text-md lg:text-base font-medium">
          {title}
        </div>
        <div className="text-2xl ml-2">{positionIcon}</div>
      </div>
      <div className="flex flex-wrap justify-center">
        {players.map((player) => (
          <PlayerCard key={player.player_id} teamOne={teamOne} {...player} />
        ))}
      </div>
    </div>
  );
};

const TeamLayout = ({
  selectedLineup,
  onSave,
  isSaved,
  showDeleteBtn = false,
  onDelete,
  showExportBtn = false,
  onInsightExport,
  customClasses = "",
  customTitle = "",
  onCreateMore,
}) => {
  const [totalSalary, setTotalSalary] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [teamOne, setTeamOne] = useState("");

  useEffect(() => {
    if (selectedLineup && selectedLineup?.length) {
      var salary = 0;
      var points = 0;
      selectedLineup.forEach((player) => {
        salary += player.salary;
        points += Math.floor(player.power_rate);
      });
      setTeamOne(selectedLineup[0].team_abbr);
      setTotalSalary(salary);
      setTotalPoints(points);
    }
  }, [selectedLineup]);

  return (
    <div
      className={`bg-[#cbe0d3] flex flex-col h-full w-[92vw] sm:w-[50vw] md:w-[45vw] xl:w-[38vw] rounded-lg mb-4 mx-2 ${customClasses}`}
    >
      {customTitle ? (
        true ? null : (
          <div className="flex flex-col items-center py-3">
            <div className="text-xl font-bold">AI Team Prediction</div>
            <div className="text-md font-semibold text-gray-500">
              {customTitle}
            </div>
          </div>
        )
      ) : (
        <div className="flex justify-center items-center">
          <div className="text-center mx-5">
            <div className="font-semibold text-base">{totalSalary}</div>
            <div className="text-sm mb-1 ">Total Salary</div>
          </div>
          <div className="text-center mx-5">
            <div className="font-semibold text-base">{totalPoints}</div>
            <div className="text-sm mb-1 ">Points Projection</div>
          </div>
        </div>
      )}
      <div className="flex flex-grow flex-col items-center">
        <div
          id="pitch"
          className="flex-grow w-full pt-1 pb-4 rounded-t-xl"
          style={{
            backgroundImage: `url(${pitchBg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <PlayerList
            players={selectedLineup.filter((i) => i.position === "WK")}
            title="Wicket Keepers"
            teamOne={teamOne}
          />
          <PlayerList
            players={selectedLineup.filter((i) => i.position === "BAT")}
            title="Batsmen"
            teamOne={teamOne}
          />
          <PlayerList
            players={selectedLineup.filter((i) => i.position === "AR")}
            title="All Rounders"
            teamOne={teamOne}
          />
          <PlayerList
            players={selectedLineup.filter((i) => i.position === "BOW")}
            title="Bowlers"
            teamOne={teamOne}
          />
        </div>
      </div>
      {showExportBtn && (
        <button
          className="bg-orange-500 text-white font-bold w-[100%] py-2 px-0 mr-0 flex items-center justify-center"
          onClick={onInsightExport}
        >
          <FaFileExport className="mr-2" />
          {"Export to Insights"}
        </button>
      )}
      {customTitle ? (
        <button
          className="bg-green-500 text-white font-bold w-[100%] py-2 px-0 mr-0 flex items-center justify-center rounded-b-xl text-lg"
          onClick={onCreateMore}
        >
          <FaArrowAltCircleRight className="mr-2" />
          {"Create More Teams"}
        </button>
      ) : showDeleteBtn ? (
        <button
          className="bg-red-500 hover:bg-red-700 text-white font-bold w-[100%] py-2 px-0 mr-0 flex items-center justify-center rounded-b-xl"
          onClick={onDelete}
        >
          <IoTrashBin className="mr-2" />
          Delete
        </button>
      ) : (
        <button
          className={`${
            isSaved
              ? "bg-green-700 cursor-not-allowed"
              : "bg-[#8cbd9f] hover:bg-green-500"
          } text-white font-bold w-[100%] py-2 px-0 mr-0 flex items-center justify-center rounded-b-xl`}
          onClick={onSave}
        >
          {isSaved ? (
            <FaCheckCircle className="mr-2" />
          ) : (
            <FaSave className="mr-2" />
          )}
          {isSaved ? "Saved" : "Save"}
        </button>
      )}
    </div>
  );
};

export default TeamLayout;
