import React from "react";
import { BsChevronRight } from "react-icons/bs";
import { BiUserCircle } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { FaHistory } from "react-icons/fa";
import { LuShoppingBag } from "react-icons/lu";
import {
  MdOutlineLogin,
  MdOutlineLogout,
  MdOutlinePrivacyTip,
} from "react-icons/md";
import SideSliderList from "./sideSliderList";
import { IoHomeOutline } from "react-icons/io5";

import { useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "../context/user";
import { auth } from "../firebase";

const Drawer = ({ open, onClose, showLoginPopup }) => {
  const { user, loggedIn } = useUserContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <div
        className={` border-black-100  shadow-2xl w-[220px] sm:w-[375px] z-[9999] h-[100vh] overflow-auto ${
          open
            ? "left-0 duration-700"
            : "left-[-110%] sm:left-[-100%] duration-700"
        } bg-white pb-2 fixed top-0 sm:top-[0]  flex-col`}
      >
        <div className="bg-gray-800 text-white p-3 md:px-8 flex justify-between ">
          <div>
            <div className="text-[22px] sm:text-[27px] font-semibold">
              {`Hey! ${user?.name || "Guest"}`}
            </div>
          </div>

          <div className="inline-block mt-2"></div>

          <div className="text-[44px] sm:text-[41px] ">
            <BiUserCircle />
          </div>
        </div>
        {pathname != "/" && (
          <SideSliderList
            title="Home"
            para="Go to home"
            logo={
              <IoHomeOutline className="w-[10%] text-[14px] sm:text-[20px]" />
            }
            extraClasss="border-b-2"
            onPress={() => {
              onClose();
              navigate("/");
            }}
          />
        )}
        <SideSliderList
          title="Profile"
          para="View your profile"
          logo={<CgProfile className="w-[10%] text-[14px] sm:text-[20px]" />}
          extraClasss="border-b-2"
          onPress={() => {
            onClose();
            navigate("/profile");
          }}
        />
        <SideSliderList
          title="History"
          para="Check our History detail"
          logo={<FaHistory className="w-[10%] text-[14px] sm:text-[20px]" />}
          extraClasss="border-b-2"
          onPress={() => {
            onClose();
            navigate("/history");
          }}
        />
        {loggedIn && (
          <SideSliderList
            title="Logout"
            logo={
              <MdOutlineLogout className="w-[10%] text-[14px] sm:text-[20px]" />
            }
            extraClasss="border-b-2"
            onPress={() =>
              auth.signOut().then(() => {
                navigate("/");
                onClose();
              })
            }
          />
        )}
      </div>
    </>
  );
};

export default Drawer;
