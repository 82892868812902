import moment from "moment";

const formats = ["ODI", "TEST", "T20", "T10"];
const teamMapping = {
  BAN: "RCB",
  CHE: "CSK",
  DEL: "DC",
  GUJ: "GT",
  HYD: "SRH",
  KOL: "KKR",
  LUC: "LSG",
  MUM: "MI",
  PUN: "PBKS",
  RAJ: "RR",
};

export const getTeamName = (val) => teamMapping?.[val] || val;

export const matchDataSanitizer = (jsonString) => {
  let item = JSON.parse(jsonString);

  const arrayProperties = [
    "playing_list",
    "ml_c_list",
    "toss_data",
    "venue_c_vc_suggestion",
    "ml_c_vc_suggestion",
    "c_vc_suggestion",
    "win_probability",
  ];

  arrayProperties.forEach((prop) => {
    if (item.hasOwnProperty(prop) && typeof item[prop] === "string") {
      try {
        item[prop] = JSON.parse(item[prop]);
      } catch (e) {
        console.error(`Error parsing property ${prop}: ${e}`);
        item[prop] = [];
      }
    }
  });

  return {
    season_game_uid: item.season_game_uid,
    home: getTeamName(item.home),
    away: getTeamName(item.away),
    league_id: item?.league_id || "",
    playing_announce: Number(item.playing_announce),
    season_scheduled_date: moment(item.season_scheduled_date)
      .add("minutes", 330)
      .valueOf(),
    home_uid: item.home_uid,
    away_uid: item.away_uid,
    format: formats[Number(item.format) - 1],
    playing_list: item?.playing_list || [],
    ml_c_list: item?.ml_c_list || [],
    venue_c_list: item?.venue_c_list || [],
    win_probability: item?.win_probability || [],
    toss_data: item?.toss_data || {},
    league_name: item?.league_name || "",
    ground_name: item?.ground_name || "",
    home_team: item.home_team,
    away_team: item.away_team,
    home_flag: `https://plineup-prod.s3.ap-south-1.amazonaws.com/upload/flag/${item.home_flag}`,
    away_flag: `https://plineup-prod.s3.ap-south-1.amazonaws.com/upload/flag/${item.away_flag}`,
  };
};

export const playerDataSanitizer = (jsonString) => {
  let arr = JSON.parse(jsonString);

  return arr.map((item) => ({
    player_id: item.player_uid,
    full_name: item.full_name,
    nick_name: item.nick_name,
    position: item.position,
    salary: Number(item.salary),
    team_uid: item.team_uid,
    team_abbr: getTeamName(item.team_abbr),
    jersey: `https://plineup-prod.s3.ap-south-1.amazonaws.com/upload/jersey/${item.jersey}`,
    last_match_played: Number(item?.last_match_played || 0),
    batting_style: item?.batting_style || "",
    bowling_style: item?.bowling_style || "",
    bowling_type: item?.bowling_type || "",
    selected_percentage: Number(item?.selected_percentage),
    tp_c_sel_perc: Number(item?.tp_c_sel_perc),
    tp_vc_sel_perc: Number(item?.tp_vc_sel_perc),
    playing_11: Number(item?.playing_11),
    lower_limit_fppg: Number(item?.lower_limit_fppg),
    upper_limit_fppg: Number(item?.upper_limit_fppg),
    player_fppg: Number(item?.player_fppg),
    rf_fppg: Number(item?.rf_fppg),
    dt_fppg: Number(item?.dt_fppg),
    power_rank: Number(item?.power_rank),
    power_rate: Math.round(
      Number(item?.power_rate) * (item?.last_match_played ? 1.05 : 0.8)
    ),
  }));
};

export const randomStringGen = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const getRandomSubset = (array, size) => {
  let result = [];
  let indices = [];
  while (result.length < size) {
    let randomIndex = Math.floor(Math.random() * array.length);
    if (!indices.includes(randomIndex)) {
      indices.push(randomIndex);
      result.push(array[randomIndex]);
    }
  }
  return result;
};

export const setCaptains = (players, index) => {
  const sortedPlayers = players.sort((a, b) => b.power_rate - a.power_rate);
  var captains = [];
  if (index === 0) {
    captains = sortedPlayers.slice(0, 2).map((player) => player.player_id);
  } else if (index === 1) {
    captains = sortedPlayers.slice(2, 4).map((player) => player.player_id);
  } else if (index > 1) {
    const top6Players = sortedPlayers
      .slice(0, 6)
      .map((player) => player.player_id);
    captains = getRandomSubset(top6Players, 2);
  }
  return players.map((player) => ({
    ...player,
    designation:
      captains[0] == player.player_id
        ? "C"
        : captains[1] == player.player_id
        ? "VC"
        : "",
  }));
};

export const insightDataSanitizer = (jsonString) => {
  let item = JSON.parse(jsonString);

  const arrayProperties = [
    "captains_list",
    "playing_list",
    "toss_data",
    "fixture_players",
    "win_probability",
  ];

  arrayProperties.forEach((prop) => {
    if (item.hasOwnProperty(prop) && typeof item[prop] === "string") {
      try {
        item[prop] = JSON.parse(item[prop]);
      } catch (e) {
        console.error(`Error parsing property ${prop}: ${e}`);
        item[prop] = [];
      }
    }
  });

  const fixture_info = item?.fixture_info || {};

  return {
    ...item,
    fixture_info: {
      ...item.fixture_info,
      home: getTeamName(fixture_info.home),
      away: getTeamName(fixture_info.away),
      season_scheduled_date: moment(fixture_info.season_scheduled_date)
        .add("minutes", 330)
        .valueOf(),
      format: formats[Number(fixture_info.format) - 1],
      captains_list: fixture_info?.captains_list || [],
      playing_list: fixture_info?.playing_list || [],
      toss_data: fixture_info?.toss_data || {},
      win_probability: fixture_info?.win_probability || {},
    },
  };
};

export const getWinProbability = (teamUid, winProbData) =>
  winProbData.team_uid == teamUid
    ? winProbData.winning_percentage
    : 100 - winProbData.winning_percentage;
