import React from "react";

import ExpandableWrapper from "../../components/expandabeWrapper";
import InsightInfo from "../../components/insightInfo";
import moment from "moment";

const HomeInsights = ({ insightData, onViewMore }) => {
  return (
    <div className="bg-white py-4 px-2">
      {insightData.map((item) => {
        const { match } = item;
        return (
          <ExpandableWrapper
            title={`${match.home} vs ${match.away}`}
            subTitle={moment(match.season_scheduled_date).format("lll")}
            onViewMore={() => onViewMore(item)}
          >
            <InsightInfo
              insightData={item.insights}
              customClasses="px-2 md:px-12"
            />
          </ExpandableWrapper>
        );
      })}
    </div>
  );
};

export default HomeInsights;
