import React from "react";

const InfoInput = ({ value, setInputData, buttonText, onPress }) => {
  return (
    <div className="w-1/2">
      <textarea
        className="w-full h-[80vh] p-2 border border-gray-300"
        value={value}
        onChange={(e) => setInputData(e.target.value)}
        placeholder="Paste JSON data here"
      ></textarea>
      <button
        className="mt-2 px-4 py-2 bg-gray-800 text-white"
        onClick={onPress}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default InfoInput;
