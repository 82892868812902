import React from "react";
import { GoDotFill } from "react-icons/go";

const PlayerName = ({ player, gameData, icon }) => {
  const playing_list = gameData?.playing_list || [];
  return (
    <td className="pl-4 pr-0 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center ">
      {!!gameData?.playing_announce && (
        <GoDotFill
          className="mr-1 "
          color={
            playing_list.includes(player.player_id) ? "#00ff00" : "#ff0000"
          }
        />
      )}
      {player.nick_name}
      <div className="ml-2">{icon}</div>
    </td>
  );
};

export default PlayerName;
