import React from "react";
import styles from "./style";
import GameCard from "../../components/gameCard";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useUserContext } from "../../context/user";

const CTA = ({ gameData }) => {
  const navigate = useNavigate();
  const { sendGAEvent } = useUserContext();

  return (
    <div className="rounded-lg shadow-sm bg-gradient-to-r from-blue-100 to-purple-100 mx-2">
      <section className={`${styles.flexCenter} flex flex-col`}>
        <div className="text-center p-6">
          <h2 className="text-gray-800 font-semibold text-3xl mb-2 block md:hidden">
            AI Generated Teams
          </h2>
          <p className="text-gray-800 text-lg block md:hidden">
            Create 10 Teams in 10 Seconds
          </p>
          <h2 className="text-gray-800 font-semibold text-3xl mb-2 hidden md:block">
            Ready to create teams?
          </h2>
          <p className="text-gray-800 text-lg hidden md:block">
            Choose a Match & Start Building
          </p>
        </div>
      </section>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-4 lg:px-12 pb-8">
        {gameData.map((game) => (
          <GameCard
            key={game.season_game_uid}
            game={game}
            onPress={() => {
              sendGAEvent("match_click_home_logged_out", {
                matchId: game.season_game_uid,
                matchDate: moment(game.season_scheduled_date).format("lll"),
                matchName: `${game.home_team}-${game.away_team}`,
              });
              navigate(`/tool/${game.id}`);
            }}
            onGradientBg
          />
        ))}
      </div>
    </div>
  );
};

export default CTA;
