import React from "react";
import { players } from "../constants/index";
import styles, { layout } from "../screens/homeLogout/style";

const InfoCard = ({ icon, title, content, index }) => (
  <div
    className={`flex flex-row py-2 rounded-[20px] w-3/4 ${
      index !== players.length - 1 ? "mb-0" : "mb-0"
    } players-card items-center`}
  >
    <div
      className={`w-[100px] h-[100px] rounded-full bg-dimBlue ${styles.flexCenter}`}
    >
      <img src={icon} alt="icon" className="w-[100%] h-[100%] object-contain" />
    </div>
    <div className="flex-1 flex flex-col ml-3 md:ml-5 ">
      <h4 className="font-poppins font-semibold text-black text-[16px] leading-[23px] mb-1">
        {title}
      </h4>
      <p className="font-poppins font-normal text-dimWhite text-[16px] leading-[24px] ">
        {content}
      </p>
    </div>
  </div>
);

const InfoModal = () => {
  return (
    <div className={`${layout.sectionImg} flex-col items-center `}>
      {players.map((players, index) => (
        <InfoCard key={players.id} {...players} index={index} />
      ))}
    </div>
  );
};

export default InfoModal;
