import React from "react";
import moment from "moment";

const getWinProbabilty = (uid, obj) => {
  if (obj.team_uid == uid) return obj.winning_percentage;
  else return 100 - obj.winning_percentage;
};

const MatchInfo = ({ gameData }) => {
  const isWinProbAvailable = !!gameData?.win_probability?.team_uid;

  return (
    <div className="w-1/2 ml-4">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="p-5 bg-gray-500 text-white">
          <h2 className="text-2xl font-bold">Match Information</h2>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <p className="font-semibold">Season Game UID:</p>
              <p className="ml-2">{gameData.season_game_uid}</p>
            </div>
            <div>
              <p className="font-semibold">League:</p>
              <p className="ml-2">{gameData.league_name}</p>
            </div>
            <div>
              <p className="font-semibold">Format:</p>
              <p className="ml-2">{gameData.format}</p>
            </div>
            <div>
              <p className="font-semibold">Date:</p>
              <p className="ml-2">
                {moment(gameData.season_scheduled_date).format("D-MMM h:mm A")}
              </p>
            </div>
            <div>
              <p className="font-semibold">Ground:</p>
              <p className="ml-2">{gameData.ground_name}</p>
            </div>
            <div>
              <p className="font-semibold">Playing 11 announced:</p>
              <p className="ml-2">{gameData.playing_announce}</p>
            </div>
          </div>
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="text-center">
              <h3 className="font-bold text-lg mb-2">Home Team</h3>
              <p className="font-semibold">{gameData.home_team}</p>
              <img
                src={gameData.home_flag}
                alt="Home Team Flag"
                className="mx-auto my-2 w-12 h-12 object-cover rounded-full border-2 border-blue-500"
              />
              {isWinProbAvailable && (
                <p className="font-semibold text-center pl-2">
                  {getWinProbabilty(
                    gameData.home_uid,
                    gameData.win_probability
                  )}
                  %
                </p>
              )}
            </div>
            <div className="text-center">
              <h3 className="font-bold text-lg mb-2">Away Team</h3>
              <p className="font-semibold">{gameData.away_team}</p>
              <img
                src={gameData.away_flag}
                alt="Away Team Flag"
                className="mx-auto my-2 w-12 h-12 object-cover rounded-full border-2 border-blue-500"
              />
              {isWinProbAvailable && (
                <p className="font-semibold text-center pl-2">
                  {getWinProbabilty(
                    gameData.away_uid,
                    gameData.win_probability
                  )}
                  %
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchInfo;
