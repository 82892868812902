import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { firestore, functions } from "../../../firebase";
import { useParams } from "react-router-dom";
import Header from "../../../components/header";

const MatchReminder = () => {
  const [users, setUsers] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { matchId } = useParams();

  useEffect(() => {
    const fetchUsers = async () => {
      const q = query(
        collection(firestore, "Users"),
        where("createdAt", ">=", new Date("2024-04-08").valueOf())
      );
      const querySnapshot = await getDocs(q);
      const fetchedUsers = [];
      querySnapshot.forEach((doc) => {
        fetchedUsers.push({ id: doc.id, ...doc.data() });
      });
      setUsers(fetchedUsers);
    };

    fetchUsers();
  }, []);

  const sendReminder = async () => {
    const sendMatchReminder = httpsCallable(functions, "sendMatchReminder");
    setIsLoading(true);

    try {
      const result = await sendMatchReminder({
        matchId,
        emails: selectedEmails,
      });
      setResponse(result.data.message);
    } catch (error) {
      setResponse("Failed to send reminders: " + error.message);
    }

    setIsLoading(false);
  };

  const handleEmailSelection = (email, isChecked) => {
    if (isChecked) {
      setSelectedEmails((prev) => [...prev, email]);
    } else {
      setSelectedEmails((prev) => prev.filter((e) => e !== email));
    }
  };

  const allSelectHandler = () => {
    if (selectedEmails.length < users.length)
      setSelectedEmails(users.map((i) => i.email));
    else setSelectedEmails([]);
  };

  return (
    <>
      <Header />
      <div className="p-4 bg-gray-50 min-h-screen mt-12">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-2xl font-bold text-center text-gray-800 mb-6">
            Send Match Reminder
          </h1>
          <div className="bg-white shadow-md rounded-lg p-6">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="matchId"
              >
                Match ID
              </label>
              <input
                id="matchId"
                type="text"
                value={matchId}
                onChange={(e) => setMatchId(e.target.value)}
                placeholder="Enter Match ID"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Select Users to Remind
              </label>
              <div className="flex mb-4">
                <p>Total: {users.length}</p>
                <p className="ml-10">Selected: {selectedEmails.length}</p>
              </div>
              <div key="all" className="flex items-center mb-2">
                <input
                  type="checkbox"
                  checked={
                    selectedEmails.length &&
                    selectedEmails.length == users.length
                  }
                  onChange={allSelectHandler}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-gray-700">Select all</span>
              </div>
              <div className="overflow-y-auto h-40 border rounded-lg p-2 bg-gray-100">
                {users.map((user) => (
                  <div key={user.id} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      checked={selectedEmails.includes(user.email)}
                      onChange={(e) =>
                        handleEmailSelection(user.email, e.target.checked)
                      }
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span className="ml-2 text-gray-700">{user.email}</span>
                  </div>
                ))}
              </div>
            </div>
            <button
              onClick={sendReminder}
              disabled={isLoading || selectedEmails.length === 0}
              className={`w-full btn btn-primary ${isLoading ? "loading" : ""}`}
            >
              {isLoading ? "Sending..." : "Send Reminder"}
            </button>
            {response && (
              <p className="mt-3 text-center text-gray-600">{response}</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MatchReminder;
