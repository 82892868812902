import React, { useState, useContext } from "react";

export const OutputContext = React.createContext();

const OutputProvider = ({ children }) => {
  const [lineupOutput, setLineupOutput] = useState([]);

  return (
    <OutputContext.Provider
      value={{
        lineupOutput,
        setLineupOutput,
      }}
    >
      {children}
    </OutputContext.Provider>
  );
};

export const useOutputContext = () => useContext(OutputContext);

export default OutputProvider;
