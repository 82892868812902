import React from "react";
import moment from "moment";
import { useBootstrapContext } from "../context/bootstrap";

function GameCard({
  game,
  onPress = () => {},
  showEditButton = false,
  showInsightButton = false,
  onEditPress = () => {},
  onInsightPress =()=>{},
  onGradientBg = false,
}) {
  const {
    home,
    away,
    season_scheduled_date,
    home_flag,
    away_flag,
    league_name,
    format,
    playing_announce,
  } = game;

  const { teamFlags } = useBootstrapContext();

  const cardBaseStyle = `rounded-lg overflow-hidden shadow-md transition-shadow duration-300 ease-in-out ${
    showEditButton || showInsightButton ? "" : "hover:cursor-pointer"
  }`;
  const cardStyleOnWhiteBg = `bg-white`;
  const cardStyleOnGradientBg = `bg-blue-50 bg-opacity-80 backdrop-filter backdrop-blur-lg`;

  return (
    <div
      className={`${cardBaseStyle} ${
        onGradientBg ? cardStyleOnWhiteBg : cardStyleOnGradientBg
      } relative`}
      onClick={onPress}
    >
      <div className="flex flex-row items-center justify-between py-10 md:py-12 px-2 md:px-4 lg:px-6">
        <div className="flex items-center justify-center lg:justify-start lg:basis-1/2">
          <img
            src={teamFlags?.[home] || home_flag}
            alt={home}
            className="w-8 h-8 md:w-10 md:h-10 mr-2"
          />
          <span className="font-semibold text-gray-800">{home}</span>
        </div>
        <div className="basis-1/4 flex flex-col items-center justify-center">
          <span className="text-sm text-gray-800">
            {moment(season_scheduled_date).format("D-MMM")}
          </span>
          <span className="text-sm text-gray-800">
            {moment(season_scheduled_date).format("h:mm A")}
          </span>
          <div className="flex items-center justify-center lg:justify-end">
          {showEditButton && (
            <button
              onClick={onEditPress}
              className="text-blue-600 hover:text-blue-800 font-bold py-2 px-4 rounded transition-colors duration-200 ease-in-out"
            >
              Edit
            </button>
          )}
          {showInsightButton && (
            <button
              onClick={onInsightPress}
              className="text-blue-600 hover:text-blue-800 font-bold py-2 px-4 rounded transition-colors duration-200 ease-in-out"
            >
              Insight
            </button>
          )}
          </div>
       
        </div>
        <div className="flex items-center justify-center lg:justify-end lg:basis-1/2">
          <span className="font-semibold text-gray-800 mr-2">{away}</span>
          <img
            src={teamFlags?.[away] || away_flag}
            alt={away}
            className="w-8 h-8 md:w-10 md:h-10"
          />
        </div>
      </div>
      <div className={`${onGradientBg ? "bg-[#f4f9ff]" : "bg-white"} py-2`}>
        <div className="text-xs text-gray-800 text-center">{league_name}</div>
        <div className="text-xs text-gray-800 font-semibold text-center">
          {format}
        </div>
      </div>
      {Math.abs(moment(season_scheduled_date).diff(moment(), "minutes")) <=
        60 ||
      (moment().diff(moment(season_scheduled_date), "seconds") >= 0 &&
        moment().local().date() ==
          moment(season_scheduled_date).local().date()) ? (
        <div className="w-full text-center mt-2 absolute bottom-14">
          {playing_announce ? (
            <p className="text-xs text-green-500">Playing 11 announced</p>
          ) : (
            <p className="text-xs text-gray-500">Players not announced</p>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default GameCard;
