import React, { useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io"; // Make sure you have react-icons installed
import { useNavigate } from "react-router-dom";
import Guide from "../screens/homeLogout/guide";
import { FaWindowClose } from "react-icons/fa";
import logoBall from "../images/logoBall.png";

const ToolHeader = ({ subTitle, title }) => {
  const [showGuideModal, setShowGuideModal] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="fixed top-0 left-0 right-0 z-50 bg-gray-800 text-white flex items-center py-2 px-2">
      <div className="flex items-center">
        <button className="text-white mr-2" onClick={() => navigate(-1)}>
          <IoMdArrowRoundBack size={24} />
        </button>
        <div className="flex flex-col">
          <span className="text-sm font-semibold">{title}</span>
          <span className="text-xs">{subTitle}</span>
        </div>
      </div>
      <div className="flex-grow flex justify-center items-center">
        <img
          src={logoBall}
          alt="Logo"
          className="object-cover"
          style={{ width: "45px", height: "45px" }} // Ensuring the logo is centered and sized correctly
          onClick={() => navigate("/")}
        />
      </div>
      <button
        className="border w-24 border-white rounded-md sm:px-4 px-3 py-1 text-white hover:bg-white hover:text-gray-800"
        onClick={() => setShowGuideModal(true)}
      >
        Guide
      </button>

      {showGuideModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-y-auto sm:pt-24">
          <div className="rounded-lg shadow-lg sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-3/4 h-[100vh] bg-white overflow-y-auto relative ">
            <Guide />
            <FaWindowClose
              className="absolute sm:top-4 top-8 sm:right-38 right-10 text-4xl sm:text-3xl font-bold text-red-600 hover:text-gray-700 z-10"
              onClick={() => setShowGuideModal(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ToolHeader;
