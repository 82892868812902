import React, { useEffect, useState } from "react";

import {
  matchDataSanitizer,
  playerDataSanitizer,
} from "../../../utils/helpers";
import Header from "../../../components/header";
import MatchInfo from "./matchInfo";
import InfoInput from "../../../components/infoInput";
import PlayersInfo from "./playersInfo";
import { firestore } from "../../../firebase";
import { collection, doc, getDoc, writeBatch } from "firebase/firestore";
import BtnLoader from "../../../components/btnLoader";
import { useNavigate, useParams } from "react-router-dom";

const UploadMatchData = () => {
  const [gameData, setGameData] = useState({});
  const [playerData, setPlayerData] = useState([]);
  const [inputGameData, setInputGameData] = useState("");
  const [inputPlayerData, setInputPlayerData] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const gameDocRef = doc(firestore, "Matches", id);
      const playerDocRef = doc(firestore, "PlayerPool", id);

      const gameDocSnap = await getDoc(gameDocRef);
      const playerDocSnap = await getDoc(playerDocRef);

      if (gameDocSnap.exists() && playerDocSnap.exists()) {
        const data = gameDocSnap.data();
        setGameData(data);
        setPlayerData(playerDocSnap.data().players);
      }
    } catch (err) {
      alert("Error");
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (id) getData();
  }, [id]);

  const handleGameSubmit = () => {
    try {
      setGameData(matchDataSanitizer(inputGameData));
    } catch (error) {
      console.log(error);
      alert("Invalid JSON data");
    }
  };

  const handlePlayerSubmit = () => {
    try {
      setPlayerData(playerDataSanitizer(inputPlayerData));
    } catch (error) {
      console.log(error);
      alert("Invalid JSON data");
    }
  };

  const onSave = async () => {
    if (showLoader) return;
    if (!Object.keys(gameData).length || !playerData.length)
      return alert("Fill complete data");
    setShowLoader(true);
    try {
      const batch = writeBatch(firestore);

      if (id) {
        const gameDataRef = doc(firestore, "Matches", id);
        const playerDataRef = doc(firestore, "PlayerPool", id);

        batch.update(gameDataRef, gameData);
        batch.update(playerDataRef, { players: playerData });
      } else {
        const gameDataRef = doc(collection(firestore, "Matches"));
        const playerDataRef = doc(firestore, "PlayerPool", gameDataRef.id);

        batch.set(gameDataRef, gameData);
        batch.set(playerDataRef, { players: playerData });
      }

      await batch.commit();
      alert("Uploaded");
      navigate("/admin");
    } catch (err) {
      console.log("err", err);
      alert("Error");
    } finally {
      setShowLoader(false);
    }
  };

  const onDelete = async () => {
    if (showDeleteLoader) return;
    setShowDeleteLoader(true);
    try {
      const batch = writeBatch(firestore);
      const gameDataRef = doc(firestore, "Matches", id);
      const playerDataRef = doc(firestore, "PlayerPool", id);
      batch.delete(gameDataRef);
      batch.delete(playerDataRef);

      await batch.commit();
      alert("Deleted");
      navigate("/admin");
    } catch (err) {
      console.log("err", err);
      alert("Error");
    } finally {
      setShowDeleteLoader(false);
    }
  };

  return (
    <div>
      <Header />
      <div className="mt-16 text-center">
        {!!id && false && (
          <button
            className="mt-2 px-8 py-2 bg-red-500 text-white rounded"
            onClick={onDelete}
          >
            {showDeleteLoader ? <BtnLoader /> : "Delete Match"}
          </button>
        )}
      </div>
      <div className="flex p-4">
        <InfoInput
          value={inputGameData}
          setInputData={setInputGameData}
          buttonText="Format Match Data"
          onPress={handleGameSubmit}
        />
        {!!Object.keys(gameData).length && <MatchInfo gameData={gameData} />}
      </div>
      {!!Object.keys(gameData).length && (
        <div className="flex p-4">
          <InfoInput
            value={inputPlayerData}
            setInputData={setInputPlayerData}
            buttonText=" Format Player Data"
            onPress={handlePlayerSubmit}
          />
          <div className="flex justify-between w-1/2 ml-2">
            <PlayersInfo
              playerData={playerData}
              teamName={gameData.home_team}
              teamFlag={gameData.home_flag}
              teamUid={gameData.home_uid}
            />
            <PlayersInfo
              playerData={playerData}
              teamName={gameData.away_team}
              teamFlag={gameData.away_flag}
              teamUid={gameData.away_uid}
            />
          </div>
        </div>
      )}

      {!!playerData.length && (
        <div className="text-center mt-4 mb-12">
          <button
            className="mt-2 px-8 py-2 bg-green-800 text-white rounded"
            onClick={onSave}
          >
            {showLoader ? <BtnLoader /> : "Upload Data"}
          </button>
        </div>
      )}
    </div>
  );
};

export default UploadMatchData;
