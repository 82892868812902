import React, { useState } from "react";
import { AiOutlineClose, AiFillApple } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";

import {
  loginHandler,
  signUpHandler,
  googleSignInHandler,
} from "../utils/authHelper";
import BtnLoader from "./btnLoader";
import ForgoPassword from "./forgotPassword";

const LoginPopup = ({ onClose, open, title = "Get Started" }) => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [isRegistered, setIsRegistered] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [showForgotPass, setShowForgotPass] = useState(false);

  const signInHandler = () => {
    if (isRegistered)
      loginHandler(email, pass, showLoader, setShowLoader, onClose);
    else
      signUpHandler(email, pass, confPass, showLoader, setShowLoader, onClose);
  };

  return showForgotPass ? (
    <ForgoPassword
      open={showForgotPass}
      onClose={() => setShowForgotPass(false)}
    />
  ) : (
    <>
      <div
        className={`fixed h-[100vh] 
        ${open ? "top-0 duration-300" : "top-[-100%] duration-300"} 
          mx-auto left-1 right-1 flex items-center justify-center z-[9999]`}
      >
        <div
          className="sm:w-[430px] w-[90%] bg-white shadow-2xl rounded-xl flex relative 
          items-center flex-col pb-4"
        >
          <AiOutlineClose
            className="absolute cursor-pointer right-4 top-3 text-[20px]"
            onClick={onClose}
          />
          <div className="py-2 font-semibold my-2 text-[15px]">{title}</div>
          <div className="py-2 flex items-center w-80 border-b-2">
            <input
              type="text"
              className="py-1 outline-none text-center"
              placeholder="Email"
              style={{ width: "100%" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="py-2 flex items-center w-80 border-b-2">
            <input
              type="text"
              className="py-1 outline-none text-center"
              placeholder="Password"
              style={{ width: "100%" }}
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
          </div>
          {!isRegistered && (
            <div className="py-2 flex items-center w-80 border-b-2">
              <input
                type="text"
                className="py-1 outline-none text-center"
                placeholder="Re-Enter Password"
                style={{ width: "100%" }}
                value={confPass}
                onChange={(e) => setConfPass(e.target.value)}
              />
            </div>
          )}
          <div
            className="border mt-[20px] w-[260px] cursor-pointer border-gray-400
           hover:bg-gray-200 duration-200 sm:w-[80%] my-2"
            onClick={signInHandler}
          >
            <div className="flex items-center justify-center py-2">
              {showLoader ? (
                <BtnLoader stroke="black" />
              ) : (
                <div>{isRegistered ? "Sign In" : "Sign Up"}</div>
              )}
            </div>
          </div>
          <span className="py-2 text-[13px]">
            {isRegistered
              ? "Don't have an account? "
              : "Already have an account? "}
            <span
              className="font-bold cursor-pointer"
              onClick={() => setIsRegistered(!isRegistered)}
            >
              {isRegistered ? "Sign Up" : "Sign In"}
            </span>
          </span>
          {isRegistered && (
            <span
              className="font-medium text-[13px] cursor-pointer"
              onClick={() => setShowForgotPass(true)}
            >
              Forgot Password
            </span>
          )}
          <div className="py-2">OR</div>
          <div
            className="border  w-[260px] cursor-pointer border-gray-400
           hover:bg-gray-200 duration-200 sm:w-[80%] my-2"
            onClick={() =>
              googleSignInHandler(showLoader, setShowLoader, onClose)
            }
          >
            <div className="flex items-center  w-[75%] justify-between py-2">
              <FcGoogle className="ms-2 text-[17px] sm:text-[22px]" />
              <div className="text-[13px] sm:text-[15px]">
                Continue With Google
              </div>
            </div>
          </div>
          <span className="py-2 text-[12px]">
            I agree To the <span className="underline">Terms & Conditions</span>{" "}
            & <span className="underline">Privacy Policy</span>
          </span>
        </div>
      </div>
    </>
  );
};

export default LoginPopup;
