import React, { useState } from "react";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
} from "react-icons/io";
import { BsFillPatchQuestionFill } from "react-icons/bs";
import { FaPlus, FaMinus } from "react-icons/fa";

const TableHeader = ({
  gameData,
  playerData,
  handleButtonClick,
  handleClick,
  selectedTeam,
  selectedCategory,
  batsmenCount,
  setBatsmenCount,
  bowlersCount,
  setBowlersCount,
  wicketKeepersCount,
  setWicketKeepersCount,
  allRoundersCount,
  setAllRoundersCount,
  setShowInfoModal,
}) => {
  const [showFilters, setShowFilters] = useState(window.innerWidth >= 768);
  const [showPlayerFilter, setShowPlayerFilter] = useState(
    window.innerWidth >= 768
  );

  const maxBatsmanAllowed = playerData.filter(
    (i) => i.position == "BAT"
  ).length;
  const maxBowlerAllowed = playerData.filter((i) => i.position == "BOW").length;
  const maxAlrounderrAllowed = playerData.filter(
    (i) => i.position == "AR"
  ).length;
  const maxWkAllowed = playerData.filter((i) => i.position == "WK").length;

  return (
    <>
      <div className="flex justify-center items-center pl-1 py-0 text-center">
        <h2 class="font-poppins text-[18px] md:text-[22px] text-black ss:leading-[100px] leading-[20px] mr-1 mt-2 md:mt-1">
          Filters
        </h2>
        <button onClick={() => setShowFilters(!showFilters)}>
          {showFilters ? (
            <IoIosArrowDropupCircle size={20} />
          ) : (
            <IoIosArrowDropdownCircle size={20} />
          )}
        </button>
      </div>
      {showFilters && (
        <>
          <div className="mt-2 md:mt-3">
            <ul className="flex cursor-pointer justify-center">
              <li
                className={`mr-4 px-3 text-sm border rounded-md ${
                  selectedTeam === "Both"
                    ? "bg-blue-500 border-blue-500 text-white"
                    : "border-black text-gray-700"
                }`}
                onClick={() => handleButtonClick("Both")}
              >
                Both
              </li>
              <li
                className={`mr-4 px-3 text-sm border rounded-md ${
                  selectedTeam === gameData.home
                    ? "bg-blue-500 border-blue-500 text-white"
                    : "border-black text-gray-700"
                }`}
                onClick={() => handleButtonClick(gameData.home)}
              >
                {gameData.home}
              </li>
              <li
                className={`mr-4 px-3 text-sm border rounded-md ${
                  selectedTeam === gameData.away
                    ? "bg-blue-500 border-blue-500 text-white"
                    : "border-black text-gray-700"
                }`}
                onClick={() => handleButtonClick(gameData.away)}
              >
                {gameData.away}
              </li>
            </ul>
          </div>
          <div className="mt-2 md:mt-3 pl-1 py-0">
            <ul className="flex cursor-pointer justify-center">
              <li
                className={`mr-4 px-3 text-sm border rounded-md ${
                  selectedCategory === "All"
                    ? "bg-blue-500 border-blue-500 text-white"
                    : "border-black text-gray-700"
                }`}
                onClick={() => handleClick("All")}
              >
                All
              </li>
              <li
                className={`mr-4 px-3 text-sm border rounded-md ${
                  selectedCategory === "BAT"
                    ? "bg-blue-500 border-blue-500 text-white"
                    : "border-black text-gray-700"
                }`}
                onClick={() => handleClick("BAT")}
              >
                BAT
              </li>
              <li
                className={`mr-4 px-3 text-sm border rounded-md ${
                  selectedCategory === "BOW"
                    ? "bg-blue-500 border-blue-500 text-white"
                    : "border-black text-gray-700"
                }`}
                onClick={() => handleClick("BOW")}
              >
                BOW
              </li>
              <li
                className={`mr-4 px-3 text-sm border rounded-md ${
                  selectedCategory === "AR"
                    ? "bg-blue-500 border-blue-500 text-white"
                    : "border-black text-gray-700"
                }`}
                onClick={() => handleClick("AR")}
              >
                AR
              </li>

              <li
                className={`mr-4 px-3 text-sm border rounded-md ${
                  selectedCategory === "WK"
                    ? "bg-blue-500 border-blue-500 text-white"
                    : "border-black text-gray-700"
                }`}
                onClick={() => handleClick("WK")}
              >
                WK
              </li>
            </ul>
          </div>
        </>
      )}
      <div className="mb-1 px-4 mt-1 md:mt-3">
        <div className="text-center mb-2">
          <div className="text-sm md:text-base flex flex-row items-center justify-center">
            <h2 className="font-poppins text-[18px] md:text-[22px] text-black ss:leading-[100px] leading-[20px] my-2">
              Minimum players
            </h2>
            <BsFillPatchQuestionFill
              id="animated-heading"
              className="mx-2 text-lg text-[#276749] cursor-pointer"
              onClick={() => setShowInfoModal(true)}
            />
            <button onClick={() => setShowPlayerFilter(!showPlayerFilter)}>
              {showPlayerFilter ? (
                <IoIosArrowDropupCircle size={20} />
              ) : (
                <IoIosArrowDropdownCircle size={20} />
              )}
            </button>
          </div>
        </div>
        {showPlayerFilter && (
          <div className="flex flex-wrap justify-around gap-2">
            <div className="flex items-center">
              <button
                className="px-2 py-1 rounded border border-gray-400 mr-2"
                onClick={() => setBatsmenCount(Math.max(1, batsmenCount - 1))}
              >
                <FaMinus size={12} />
              </button>
              <span className="text-sm md:text-md">BAT: {batsmenCount}</span>
              <button
                className="px-2 py-1 rounded border border-gray-400 ml-2"
                onClick={() =>
                  setBatsmenCount(
                    Math.min(batsmenCount + 1, 8, maxBatsmanAllowed)
                  )
                }
              >
                <FaPlus size={12} />
              </button>
            </div>

            <div className="flex items-center">
              <button
                className="px-2 py-1 rounded border border-gray-400 mr-2"
                onClick={() =>
                  setAllRoundersCount(Math.max(1, allRoundersCount - 1))
                }
              >
                <FaMinus size={12} />
              </button>
              <span className="text-sm md:text-md">AR: {allRoundersCount}</span>
              <button
                className="px-2 py-1 rounded border border-gray-400 ml-2"
                onClick={() =>
                  setAllRoundersCount(
                    Math.min(allRoundersCount + 1, 8, maxAlrounderrAllowed)
                  )
                }
              >
                <FaPlus size={12} />
              </button>
            </div>

            <div className="flex items-center">
              <button
                className="px-2 py-1 rounded border border-gray-400 mr-2"
                onClick={() =>
                  setWicketKeepersCount(Math.max(1, wicketKeepersCount - 1))
                }
              >
                <FaMinus size={12} />
              </button>
              <span className="text-sm md:text-md">
                WK: {wicketKeepersCount}
              </span>
              <button
                className="px-2 py-1 rounded border border-gray-400 ml-2"
                onClick={() =>
                  setWicketKeepersCount(
                    Math.min(wicketKeepersCount + 1, 8, maxWkAllowed)
                  )
                }
              >
                <FaPlus size={12} />
              </button>
            </div>

            <div className="flex items-center">
              <button
                className="px-2 py-1 rounded border border-gray-400 mr-2"
                onClick={() => setBowlersCount(Math.max(1, bowlersCount - 1))}
              >
                <FaMinus size={12} />
              </button>
              <span className="text-sm md:text-md">BOW: {bowlersCount}</span>
              <button
                className="px-2 py-1 rounded border border-gray-400 ml-2"
                onClick={() =>
                  setBowlersCount(
                    Math.min(bowlersCount + 1, 8, maxBowlerAllowed)
                  )
                }
              >
                <FaPlus size={12} />
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TableHeader;
