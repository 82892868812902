import React, { useEffect, useState } from "react";
import { FaLock, FaStar, FaTrash, FaPlus, FaMinus } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

import { GiSpikedBat, GiBaseballGlove } from "react-icons/gi";
import { FaWindowClose } from "react-icons/fa";
import { BiSolidCricketBall } from "react-icons/bi";
import TableFooter from "./components/tableFooter";
import { useOutputContext } from "../../context/output";
import { doc, getDoc } from "firebase/firestore";
import ToolHeader from "../../components/toolHeader";
import Header from "../../components/header";
import PageLoader from "../../components/pageLoader";
import { firestore } from "../../firebase";
import { randomStringGen, setCaptains } from "../../utils/helpers";
import PlayerName from "../../components/playerName";
import LoginPopup from "../../components/loginPopup";
import { useUserContext } from "../../context/user";
import { BsFillPatchQuestionFill } from "react-icons/bs";
import InfoModal from "../../components/infoModal";
import ActionInfoModal from "../../components/actionInfoModal";
import "./index.css";
import TableHeader from "./components/tableHeader";
import InsightMini from "../../components/insightMini";

const Tool = () => {
  const [gameData, setGameData] = useState({});
  const [playerData, setPlayerData] = useState([]);
  const [dataToShow, setDataToShow] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const { id } = useParams();

  const getData = async () => {
    setShowLoader(true);
    try {
      const gameDocRef = doc(firestore, "Matches", id);
      const playerDocRef = doc(firestore, "PlayerPool", id);

      const gameDocSnap = await getDoc(gameDocRef);
      const playerDocSnap = await getDoc(playerDocRef);

      if (gameDocSnap.exists() && playerDocSnap.exists()) {
        const data = gameDocSnap.data();
        const pool = playerDocSnap.data().players;
        setGameData(data);
        setPlayerData(pool);
        if (data?.playing_announce && data?.playing_list?.length)
          setRemovedPlayers(
            pool
              .filter((i) => !data.playing_list.includes(i.player_id))
              .map((i) => i.player_id)
          );
        else {
          var lastMatchPlayedPool = pool.filter((i) => i.last_match_played);
          if (
            lastMatchPlayedPool &&
            lastMatchPlayedPool?.length &&
            lastMatchPlayedPool.length > 16
          )
            setRemovedPlayers(
              pool.filter((i) => !i.last_match_played).map((i) => i.player_id)
            );
        }
      }
    } catch (err) {
      alert("Error");
      console.log("err", err);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, [id]);

  const [activeTab, setActiveTab] = useState("allPlayers");
  const [favoritePlayers, setFavoritePlayers] = useState([]);
  const [lockedPlayers, setLockedPlayers] = useState([]);
  const [removedPlayers, setRemovedPlayers] = useState([]);
  const [teamCount, setTeamCount] = useState(1);
  const [batsmenCount, setBatsmenCount] = useState(1);
  const [allRoundersCount, setAllRoundersCount] = useState(1);
  const [wicketKeepersCount, setWicketKeepersCount] = useState(1);
  const [bowlersCount, setBowlersCount] = useState(1);
  const [showBtnLoader, setShowBtnLoader] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState("Both");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showActionInfoModal, setShowActionInfoModal] = useState(false);
  const [teamsRequested, setTeamsRequested] = useState(false);

  const handleButtonClick = (buttonName) => {
    setSelectedTeam(buttonName);
  };
  const handleClick = (buttonName) => {
    setSelectedCategory(buttonName);
  };

  const navigate = useNavigate();
  const { setLineupOutput } = useOutputContext();
  const { loggedIn } = useUserContext();

  const generateTeams = () => {
    if (showBtnLoader) return;
    const playerPool = playerData.filter(
      (i) => !removedPlayers.includes(i.player_id)
    );
    if (playerPool.length < 11) return alert("Atleast 11 players required");
    if (
      batsmenCount + bowlersCount + wicketKeepersCount + allRoundersCount >
      11
    )
      return alert("Total of minimum players cannot be greater than 11");

    setShowBtnLoader(true);
    fetch("https://generate-lineups-ke33qvjuza-uc.a.run.app", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        players: playerPool,
        locked_players: lockedPlayers,
        favorite_players: favoritePlayers,
        total_teams: teamCount,
        total_batsman: batsmenCount,
        total_bowler: bowlersCount,
        total_alrounder: allRoundersCount,
        total_wk: wicketKeepersCount,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.length) {
          var tempArr = {};
          data.forEach(
            (item, index) =>
              (tempArr[randomStringGen(10)] = setCaptains(item, index))
          );
          setLineupOutput(tempArr);
          navigate(`/genereatedTeams/${id}`);
        } else
          alert(
            "No lineups generated. Please try again with different constraints."
          );
      })
      .catch((err) => console.log("err", err))
      .finally(() => setShowBtnLoader(false));
  };

  useEffect(() => {
    if (!showLoginPopup && teamsRequested && loggedIn) {
      generateTeams();
      setTeamsRequested(false);
    }
  }, [showLoginPopup, loggedIn]);

  const handleFavorite = (playerId) => {
    const isFavorited = favoritePlayers.includes(playerId);
    if (isFavorited) {
      setFavoritePlayers(favoritePlayers.filter((id) => id !== playerId));
    } else {
      setFavoritePlayers([...favoritePlayers, playerId]);
    }
  };

  const handleLock = (playerId) => {
    const isLocked = lockedPlayers.includes(playerId);
    if (isLocked) {
      setLockedPlayers(lockedPlayers.filter((id) => id !== playerId));
    } else {
      setLockedPlayers([...lockedPlayers, playerId]);
    }
  };

  const handleRemove = (playerId) => {
    if (!removedPlayers.includes(playerId)) {
      setRemovedPlayers([...removedPlayers, playerId]);
    }
  };

  const handleAddBack = (playerId) => {
    setRemovedPlayers(removedPlayers.filter((id) => id !== playerId));
  };
  const roleIcons = {
    BAT: <GiSpikedBat color="#E48F45" className="h-5 w-5" />,
    BOW: <BiSolidCricketBall color="#7D0A0A" className="h-4 w-4" />,
    WK: <GiBaseballGlove color="#9BB0C1" className="h-5 w-5" />,
    AR: (
      <div className="flex items-center">
        <GiSpikedBat color="#E48F45" className="h-5 w-5" />
        <BiSolidCricketBall color="#7D0A0A" className="h-4 w-4" />
      </div>
    ),
  };

  useEffect(() => {
    if (playerData.length) {
      var tempArr = playerData
        .filter((i) => i.team_abbr == selectedTeam || selectedTeam == "Both")
        .filter(
          (i) => i.position == selectedCategory || selectedCategory == "All"
        );
      tempArr.sort((i, j) => j.power_rate - i.power_rate);
      setDataToShow(tempArr);
    }
  }, [playerData, selectedTeam, selectedCategory]);

  return showLoader ? (
    <div>
      <Header />
      <PageLoader />
    </div>
  ) : (
    <div className="min-h-screen bg-gray-100">
      <ToolHeader
        title="CREATE TEAMS"
        subTitle={`${gameData.home} vs ${gameData.away}`}
      />
      <div className="container mx-auto relative pt-6 mt-10 md:mt-14 ">
        {!!gameData?.insightExists && (
          <InsightMini gameData={gameData} id={id} />
        )}
        <TableHeader
          gameData={gameData}
          playerData={playerData}
          selectedTeam={selectedTeam}
          selectedCategory={selectedCategory}
          handleButtonClick={handleButtonClick}
          handleClick={handleClick}
          batsmenCount={batsmenCount}
          setBatsmenCount={setBatsmenCount}
          bowlersCount={bowlersCount}
          setBowlersCount={setBowlersCount}
          wicketKeepersCount={wicketKeepersCount}
          setWicketKeepersCount={setWicketKeepersCount}
          allRoundersCount={allRoundersCount}
          setAllRoundersCount={setAllRoundersCount}
          setShowInfoModal={setShowInfoModal}
        />

        <div className="mb-0 mt-2 pl-1 py-2">
          <ul className="flex cursor-pointer">
            <li
              className={`mr-2 md:mr-4 px-2 md:px-4 py-1 rounded-t-lg ${
                activeTab === "allPlayers"
                  ? "bg-white border-l border-t border-r"
                  : "bg-gray-200"
              }`}
              onClick={() => setActiveTab("allPlayers")}
            >
              All Players (
              {
                dataToShow.filter((i) => !removedPlayers.includes(i.player_id))
                  .length
              }
              )
            </li>
            <li
              className={`mr-4 px-4 py-1 rounded-t-lg ${
                activeTab === "removedPlayers"
                  ? "bg-white border-l border-t border-r"
                  : "bg-gray-200"
              }`}
              onClick={() => setActiveTab("removedPlayers")}
            >
              Removed Players ({removedPlayers.length})
            </li>
          </ul>
        </div>

        <div className="overflow-x-auto shadow-lg rounded-lg pb-24 bg-white">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                  Player
                </th>
                <th className="px-6 py-5 text-left text-xs font-medium flex flex-row items-center text-gray-600 uppercase tracking-wider ">
                  Actions
                  <BsFillPatchQuestionFill
                    id="animated-heading"
                    className="ml-1 text-lg text-[#276749] cursor-pointer"
                    onClick={() => setShowActionInfoModal(true)}
                  />
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                  Power Rate
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                  Team
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                  Credit
                </th>
              </tr>
            </thead>

            <tbody>
              {activeTab === "allPlayers" &&
                dataToShow.map(
                  (player) =>
                    !removedPlayers.includes(player.player_id) && (
                      <tr
                        key={player.player_id}
                        className={
                          lockedPlayers.includes(player.player_id)
                            ? "bg-blue-50"
                            : favoritePlayers.includes(player.player_id)
                            ? "bg-yellow-50"
                            : ""
                        }
                      >
                        <PlayerName
                          player={player}
                          gameData={gameData}
                          icon={roleIcons[player.position]}
                        />
                        <td className="px-6 py-4 whitespace-nowrap text-lg text-gray-500 ">
                          <button
                            onClick={() => handleFavorite(player.player_id)}
                            className={`p-0 ${
                              favoritePlayers.includes(player.player_id)
                                ? "text-yellow-400"
                                : "text-gray-400"
                            } `}
                          >
                            <FaStar />
                          </button>
                          <button
                            onClick={() => handleLock(player.player_id)}
                            className={`p-2 ${
                              lockedPlayers.includes(player.player_id)
                                ? "text-blue-400"
                                : "text-gray-400"
                            } `}
                          >
                            <FaLock />
                          </button>
                          <button
                            onClick={() => handleRemove(player.player_id)}
                            className="p-2 text-red-400 hover:text-red-600"
                          >
                            <FaTrash />
                          </button>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {player.power_rate}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {player.team_abbr}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                          {player.salary}
                        </td>
                      </tr>
                    )
                )}

              {activeTab === "removedPlayers" &&
                playerData.map(
                  (player) =>
                    removedPlayers.includes(player.player_id) && (
                      <tr key={player.player_id} className="hover:bg-gray-50">
                        <PlayerName
                          player={player}
                          gameData={gameData}
                          icon={roleIcons[player.position]}
                        />
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <button
                            onClick={() => handleAddBack(player.player_id)}
                            className="p-2 text-green-500 hover:text-green-700"
                          >
                            <FaPlus />
                          </button>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap  text-sm font-medium">
                          {player.power_rate}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {player.team_abbr}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap  text-sm font-medium">
                          {player.salary}
                        </td>
                      </tr>
                    )
                )}
            </tbody>
          </table>
        </div>
        <TableFooter
          teamCount={teamCount}
          setTeamCount={setTeamCount}
          generateTeams={() => {
            generateTeams();
            // if (loggedIn) generateTeams();
            // else {
            //   setShowLoginPopup(true);
            //   setTeamsRequested(true);
            // }
          }}
          showBtnLoader={showBtnLoader}
          game={gameData}
        />
      </div>
      {showLoginPopup && (
        <LoginPopup
          onClose={() => setShowLoginPopup(false)}
          open={showLoginPopup}
        />
      )}
      {showInfoModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-y-auto sm:pt-24">
          <div className="rounded-lg shadow-lg sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-3/4  bg-white overflow-y-auto relative ">
            <InfoModal />
            <FaWindowClose
              className="absolute sm:top-4 top-2 sm:right-38 right-2 text-4xl sm:text-3xl font-bold text-red-600 hover:text-gray-700 z-10"
              onClick={() => setShowInfoModal(false)}
            />
          </div>
        </div>
      )}
      {showActionInfoModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-y-auto sm:pt-24">
          <div className="rounded-lg shadow-lg sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-3/4  bg-white overflow-y-auto relative ">
            <ActionInfoModal />
            <FaWindowClose
              className="absolute sm:top-4 top-0 sm:right-38 right-3 text-4xl sm:text-3xl font-bold text-red-600 hover:text-gray-700 z-10"
              onClick={() => setShowActionInfoModal(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Tool;
