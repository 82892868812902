import React from "react";
import { useBootstrapContext } from "../context/bootstrap";
import { getTeamName, getWinProbability } from "../utils/helpers";
import TeamLayout from "../screens/teamLayout";

const TeamSection = ({ team, teamUid, winProbData, teamFlags }) => (
  <div className="flex flex-col items-center w-full px-2 py-1">
    <p className="font-semibold text-xs sm:text-sm md:text-base">{team}</p>
    <img
      src={teamFlags[team]}
      alt={`${team} Flag`}
      className="my-1 rounded-full object-cover w-10 h-10 sm:w-12 sm:h-12 md:w-14 md:h-14"
    />
    <p className="font-semibold text-sm sm:text-md md:text-lg">
      {winProbData && `${getWinProbability(teamUid, winProbData)}%`}
    </p>
  </div>
);

const SectionHeader = ({ title }) => (
  <h3 className="underline text-center my-5 mx-2 text-md font-bold sm:text-lg">
    {title}
  </h3>
);

const ProgressBar = ({ color, width, label }) => (
  <div
    className={`flex items-center justify-center py-2 px-1 text-xs sm:text-sm md:text-base text-white ${color}`}
    style={{ width: `${width}%`, minWidth: "25%", maxWidth: "75%" }}
  >
    <span className="text-sm sm:text-lg md:font-semibold">{width}%</span>
    <span className="mx-1 sm:mx-2">{label}</span>
  </div>
);

const TopPlayers = ({ players, teamFlags }) => {
  const playersByTeam = players.reduce((acc, player) => {
    const teamName = getTeamName(player.team_abbr);
    acc[teamName] = acc[teamName] || [];
    acc[teamName].push(player);
    return acc;
  }, {});

  Object.values(playersByTeam).forEach((players) => {
    players.sort((a, b) => b.avg_fpts - a.avg_fpts);
    players.splice(3);
  });

  return (
    <div className="flex justify-around w-full">
      {Object.entries(playersByTeam).map(([team, topPlayers]) => (
        <div key={team} className="flex flex-col items-center w-1/2">
          {topPlayers.map((player) => (
            <div
              key={player.id}
              className="flex flex-col items-center p-2 mb-2"
            >
              <img
                src={teamFlags[getTeamName(player.team_abbr)]}
                alt={`${team} Flag`}
                className="rounded-full object-cover w-10 h-10"
              />
              <div className="mt-1 flex flex-col items-center">
                <h5 className="text-sm font-bold">{player.full_name}</h5>
                <p className="text-xs text-gray-500">{player.position}</p>
                <p className="text-md font-bold">{player.avg_fpts} pts</p>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const InsightInfo = ({ insightData, customClasses, onCreateMore }) => {
  const { teamFlags } = useBootstrapContext();
  const {
    fixture_info: gameData = {},
    venue_info = {},
    fixture_players = [],
    lineup_players = [],
  } = insightData || {};

  return (
    <div className={`w-full ${customClasses}`}>
      <SectionHeader title="Winning Probability" />
      <div className="flex justify-center items-center gap-4">
        <TeamSection
          team={gameData.home}
          teamUid={gameData.home_uid}
          winProbData={gameData.win_probability}
          teamFlags={teamFlags}
        />
        <TeamSection
          team={gameData.away}
          teamUid={gameData.away_uid}
          winProbData={gameData.win_probability}
          teamFlags={teamFlags}
        />
      </div>

      {!!lineup_players?.length && (
        <>
          <SectionHeader title="AI Team Prediction" />
          <div className="flex items-center justify-center mx-auto md:max-w-5xl">
            <TeamLayout
              selectedLineup={lineup_players}
              customClasses="w-[96vw] mx-0"
              customTitle={`${gameData.home} vs ${gameData.away}`}
              onCreateMore={onCreateMore}
            />
          </div>
        </>
      )}

      <SectionHeader title="Scoring Pattern" />
      <div className="flex justify-around mt-5">
        <div className="text-center">
          <h3 className="text-lg font-bold">Avg. Score</h3>
          <p className="p-6 py-8 bg-gray-300 rounded-b-full text-xl font-semibold">
            {venue_info.avg_score}
          </p>
        </div>
        <div className="text-center">
          <h3 className="text-lg font-bold">Avg. Wickets</h3>
          <p className="p-6 py-8 bg-gray-300 rounded-b-full text-xl font-semibold">
            {venue_info.avg_wicket}
          </p>
        </div>
      </div>

      <SectionHeader title="Toss Trends" />
      <div className="flex justify-around mt-4">
        <div className="w-full text-center">
          <div className="flex h-10 overflow-hidden rounded bg-gray-100">
            <ProgressBar
              color="bg-teal-500"
              width={venue_info.toss_trend_per.choose_bat_first}
              label="Choose to Bat"
            />
            <ProgressBar
              color="bg-rose-500"
              width={venue_info.toss_trend_per.choose_bowl_first}
              label="Choose to Bowl"
            />
          </div>
        </div>
      </div>

      <SectionHeader title="Match Outcomes" />
      <div className="flex justify-around mt-4">
        <div className="w-full text-center">
          <div className="flex h-10 overflow-hidden rounded bg-gray-100">
            <ProgressBar
              color="bg-indigo-500"
              width={venue_info.toss_trend_per.bat_first_win}
              label="Wins Batting First"
            />
            <ProgressBar
              color="bg-lime-500"
              width={venue_info.toss_trend_per.bat_second_win}
              label="Wins Chasing"
            />
          </div>
        </div>
      </div>

      <SectionHeader title="Top Players" />
      <TopPlayers players={fixture_players} teamFlags={teamFlags} />
    </div>
  );
};

export default InsightInfo;
