import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
  doc,
  getDoc,
  startAfter,
} from "firebase/firestore";
import moment from "moment";

import Header from "../../components/header";
import GameCard from "../../components/gameCard";
import { firestore } from "../../firebase";
import PageLoader from "../../components/pageLoader";
import { useUserContext } from "../../context/user";

const Home = () => {
  const [gameData, setGameData] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [page, setPage] = useState(0);
  const [dataToShow, setDataToShow] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const maxDocLimit = 6;
  const navigate = useNavigate();
  const { userId, sendGAEvent } = useUserContext();

  const fetchData = async () => {
    try {
      setShowLoader(true);
      var gameQuery = query(
        collection(firestore, "SavedLineups"),
        where("userId", "==", userId),
        orderBy("match_start_time", "desc"),
        limit(maxDocLimit)
      );
      const snap = await getDocs(gameQuery);
      if (snap.docs.length) {
        const newData = [];
        for (const item of snap.docs) {
          const data = item.data();
          var matchDocSnap = await getDoc(
            doc(firestore, "Matches", data.matchId)
          );
          var matchData = matchDocSnap.data();
          newData.push({
            ...matchData,
            lineups: data.lineups,
            matchId: data.matchId,
          });
        }
        setGameData(newData);
        if (snap.docs.length == maxDocLimit)
          setLastDoc(snap.docs[snap.docs.length - 1]);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchMoreData = async () => {
    if (gameData.length > page + maxDocLimit) setPage(page + maxDocLimit);
    else {
      try {
        setShowLoader(true);
        var gameQuery = query(
          collection(firestore, "SavedLineups"),
          where("userId", "==", userId),
          orderBy("match_start_time", "desc"),
          startAfter(lastDoc),
          limit(maxDocLimit)
        );
        var snap = await getDocs(gameQuery);

        if (snap.docs.length) {
          const newData = [];
          for (const item of snap.docs) {
            const data = item.data();
            var matchDocSnap = await getDoc(
              doc(firestore, "Matches", data.matchId)
            );
            var matchData = matchDocSnap.data();
            newData.push({
              ...matchData,
              lineups: data.lineups,
              matchId: data.matchId,
            });
          }
          setGameData([...gameData, ...newData]);
          setPage(page + maxDocLimit);
          if (snap.docs.length == maxDocLimit)
            setLastDoc(snap.docs[snap.docs.length - 1]);
          else setLastDoc(null);
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        setShowLoader(false);
      }
    }
  };

  useEffect(() => {
    if (!gameData.length) return;
    var data = gameData.slice(page, page + maxDocLimit);
    setDataToShow(data.filter((i) => Object.keys(i.lineups).length));
  }, [gameData, page, lastDoc]);

  return (
    <div>
      <Header />
      {showLoader ? (
        <PageLoader />
      ) : !gameData.length ? (
        <div className="text-[18px] text-center mt-4  ">
          No data found for previous lineups.
        </div>
      ) : (
        <>
          <div className="text-[18px] font-medium text-center text-black mt-16">
            Previous History
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-4 lg:px-12 py-2">
            {dataToShow.map((game) => (
              <GameCard
                key={game.season_game_uid}
                game={game}
                onPress={() => {
                  sendGAEvent("match_click_history", {
                    matchId: game.season_game_uid,
                    matchDate: moment(game.season_scheduled_date).format("lll"),
                    matchName: `${game.home_team}-${game.away_team}`,
                  });
                  navigate(`/history/${game.matchId}`);
                }}
              />
            ))}
          </div>
          <div className="flex justify-center mb-4">
            {!!page && (
              <button
                className="bg-gray-600 hover:bg-gray-800 py-2 px-4 rounded mx-2 text-white"
                onClick={() => setPage(page - maxDocLimit)}
              >
                Prev
              </button>
            )}
            {(!!lastDoc || gameData.length > page + maxDocLimit) && (
              <button
                className="bg-gray-600 hover:bg-gray-800 py-2 px-4 rounded mx-2 text-white"
                onClick={fetchMoreData}
              >
                Next
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
