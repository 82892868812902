import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { doc, getDoc } from "firebase/firestore";
import ToolHeader from "../../components/toolHeader";
import { firestore } from "../../firebase";
import InsightInfo from "../../components/insightInfo";
import PageLoader from "../../components/pageLoader";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { useUserContext } from "../../context/user";
import moment from "moment";

const MatchInsights = () => {
  const [gameData, setGameData] = useState(null);
  const [insightData, setInsightData] = useState(null);

  const { matchid } = useParams();
  const navigate = useNavigate();
  const { sendGAEvent } = useUserContext();

  const getData = async () => {
    try {
      const docRef = doc(firestore, "Matches", matchid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setGameData(data);
      }
    } catch (err) {
      alert("Error");
      console.log("err", err);
    }
  };

  const getInsightData = async () => {
    try {
      const docRef = doc(firestore, "Insights", matchid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setInsightData(data);
      }
    } catch (err) {
      alert("Error");
      console.log("err", err);
    }
  };

  useEffect(() => {
    getData();
    getInsightData();
  }, [matchid]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const onCreateMore = () => {
    sendGAEvent("create_more_teams_clicked", {
      matchId: gameData.season_game_uid,
      matchDate: moment(gameData.season_scheduled_date).format("lll"),
      matchName: `${gameData.home_team}-${gameData.away_team}`,
    });
    navigate(`/tool/${matchid}`);
  };

  return (
    <div className="min-h-screen " onScroll={() => console.log("a")}>
      <ToolHeader
        title={"Match Insights"}
        subTitle={gameData ? `${gameData.home} vs ${gameData.away}` : ""}
      />
      {gameData && insightData ? (
        <>
          <InsightInfo
            insightData={insightData}
            customClasses="mt-20 px-2 md:px-12"
            matchId={matchid}
            onCreateMore={onCreateMore}
          />
          <div className="flex justify-center">
            <button
              onClick={onCreateMore}
              className="py-3 my-4 w-52 text-center bg-green-600 text-sm flex justify-center items-center font-semibold rounded-lg"
            >
              <div className="text-white text-base">Create More Teams</div>
              <FaChevronRight className="text-white text-sm ml-2" />
            </button>
          </div>
        </>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

export default MatchInsights;
