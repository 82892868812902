import React, { useState, useContext, useRef, useEffect } from "react";
import { getDoc, onSnapshot, doc, setDoc } from "firebase/firestore";

import { auth, firestore } from "../firebase";
import { getAnalytics, logEvent } from "firebase/analytics";
import moment from "moment";

const singleLogEvents = [
  "home_visit_logged_out",
  "home_visit_logged_in",
  "match_click_home_logged_out",
  "home_signin_attempt",
  "generate_teams_logged_out",
];

export const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(null);
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(true);
  const [loggedEvents, setLoggedEvents] = useState([]);

  const userSubscriberRef = useRef();
  const analytics = getAnalytics();

  const onAuthStateChanged = async (user) => {
    if (user) {
      const id = user?.uid;
      const docRef = doc(firestore, "Users", id);
      const snap = await getDoc(docRef);
      if (!snap.exists()) {
        sendGAEvent("signup_success");
        const date = new Date();
        const data = user?.providerData?.[0];
        const payload = {
          email: data?.email ? data.email.toLowerCase() : "",
          userId: id,
          createdAt: date.valueOf(),
          authMethod: data?.providerId,
          authAccount: data?.email,
        };
        try {
          await setDoc(docRef, payload);
        } catch (err) {
          console.log("err", err);
        }
      }
      setUserId(id);
    } else {
      setLoggedIn(false);
      setUserId("");
      setUser(null);
      if (userSubscriberRef.current) userSubscriberRef.current();
    }
  };

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);

    return () => {
      subscriber();
    };
  }, []);

  useEffect(() => {
    if (!userId) return;
    const docRef = doc(firestore, "Users", userId);
    const userSubscriber = onSnapshot(docRef, (snap) => {
      if (snap.exists()) {
        setUser(snap.data());
      }
      setLoggedIn(true);
    });
    userSubscriberRef.current = userSubscriber;
    return userSubscriber;
  }, [userId]);

  const logoutHandler = (navigate, callback = () => {}) => {
    auth
      .signOut()
      .then(() => {
        navigate("/");
        callback();
      })
      .catch((err) => console.log("err", err));
  };

  useEffect(() => {
    if (userId == "jwhbCDJ5npZ7A9pX2QjS6tVEWQh2") setIsAdmin(true);
    else setIsAdmin(false);
  }, [userId]);

  const sendGAEvent = (eventName, params = {}) => {
    try {
      if (singleLogEvents.includes(eventName)) {
        if (
          loggedEvents.includes(eventName) ||
          (eventName == "home_visit_logged_in" &&
            loggedEvents.includes("home_visit_logged_out"))
        )
          return;
        else {
          var tempArr = [...loggedEvents];
          tempArr.push(eventName);
          setLoggedEvents(tempArr);
        }
      }

      const urlParams = new URLSearchParams(window.location.search);
      const utmSource = urlParams.get("utm_source") || "direct";

      const eventParams = {
        ...params,
        user_state: loggedIn ? "logged_in" : "logged_out",
        userId,
        utm_source: utmSource,
      };
      logEvent(analytics, eventName, eventParams);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    try {
      const lastVisit = localStorage.getItem("lastVisit");
      const currentDate = moment().format("l");

      if (!lastVisit) localStorage.setItem("lastVisit", currentDate);
      else {
        if (lastVisit != currentDate) {
          sendGAEvent("user_re_visit", { lastVisit });
          localStorage.setItem("lastVisit", currentDate);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        isAdmin,
        user,
        userId,
        logoutHandler,
        sendGAEvent,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);

export default UserProvider;
