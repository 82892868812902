import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { deleteField, doc, getDoc, updateDoc } from "firebase/firestore";

import ToolHeader from "../../components/toolHeader";
import PlayerTable from "../../components/playersTable";
import TeamLayout from "../teamLayout";
import { firestore } from "../../firebase";
import { useUserContext } from "../../context/user";
import PageLoader from "../../components/pageLoader";

const LineupHistory = () => {
  const [gameData, setGameData] = useState(null);
  const [selectedLineupId, setSelectedLineupId] = useState(null);
  const [savedLineups, setSavedLineups] = useState([]);
  const [lineupOutput, setlineupOutput] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [refetch, setRefech] = useState(0);

  const { userId } = useUserContext();
  const { matchid } = useParams();

  const getData = async () => {
    try {
      const gameDocRef = doc(firestore, "Matches", matchid);
      const gameDocSnap = await getDoc(gameDocRef);

      if (gameDocSnap.exists()) {
        const data = gameDocSnap.data();
        setGameData(data);
      }
    } catch (err) {
      alert("Error");
      console.log("err", err);
    }
  };

  const getLineupData = async () => {
    setShowLoader(true);
    try {
      const docRef = doc(firestore, "SavedLineups", `${matchid}_${userId}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setlineupOutput(data.lineups);
      }
    } catch (err) {
      alert("Error");
      console.log("err", err);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [matchid]);

  useEffect(() => {
    getLineupData();
  }, [refetch]);

  const onDelete = async (lineupId) => {
    if (savedLineups.includes(lineupId)) return;
    setSavedLineups([...savedLineups, lineupId]);
    try {
      const docRef = doc(firestore, "SavedLineups", `${matchid}_${userId}`);

      await updateDoc(docRef, {
        [`lineups.${lineupId}`]: deleteField(),
      });
      setRefech(refetch + 1);
    } catch (err) {
      console.log("err", err);
      setSavedLineups([...savedLineups.filter((i) => i != lineupId)]);
      alert("Error");
    }
  };

  return (
    <div className="min-h-screen ">
      <ToolHeader
        title={"Teams"}
        subTitle={gameData ? `${gameData.home} vs ${gameData.away}` : ""}
      />
      {showLoader ? (
        <PageLoader />
      ) : (
        <>
          <div className="flex flex-wrap justify-center gap-6 sm:gap-0 my-16 ">
            {Object.keys(lineupOutput).map((id) => (
              <TeamLayout
                selectedLineup={lineupOutput[id]}
                showDeleteBtn={true}
                onDelete={() => onDelete(id)}
              />
            ))}
          </div>
          {!!selectedLineupId && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-2 rounded-lg h-full overflow-y-auto">
                <FaTimes
                  className="absolute top-2 text-red-500 cursor-pointer"
                  onClick={() => setSelectedLineupId(null)}
                />
                <TeamLayout selectedLineup={lineupOutput[selectedLineupId]} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LineupHistory;
