import React, { useState, useContext, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase";

export const BootstrapContext = React.createContext();

const BootstrapProvider = ({ children }) => {
  const [teamFlags, setTeamFlags] = useState({});
  const [teamJerseys, setTeamJerseys] = useState({});

  const getBootstrapData = async () => {
    try {
      const docRef = doc(firestore, "Bootstrap", "Hs5sdN5lvcgRso96g3mU");
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      if (!data) return;
      if (data?.flags) setTeamFlags(data.flags);
      if (data?.jerseys) setTeamJerseys(data.jerseys);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getBootstrapData();
  }, []);

  return (
    <BootstrapContext.Provider
      value={{
        teamFlags,
        teamJerseys,
      }}
    >
      {children}
    </BootstrapContext.Provider>
  );
};

export const useBootstrapContext = () => useContext(BootstrapContext);

export default BootstrapProvider;
