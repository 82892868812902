import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../screens/home";
import Tool from "../screens/tool";
import TeamLayout from "../screens/teamLayout";
import UpcomingMatches from "../screens/admin";
import UploadMatchData from "../screens/admin/uploadMatchData";
import Login from "../screens/login";
import PrivateRoute from "./privateRoute";
import AdminRoute from "./adminRoute";
import GenereatedTeams from "../screens/generatedTeams";
import Profile from "../screens/profile";
import History from "../screens/history";
import LineupHistory from "../screens/lineupHistory";
import { useUserContext } from "../context/user";
import HomeLogout from "../screens/homeLogout";
import Header from "../components/header";
import PageLoader from "../components/pageLoader";
import MatchReminder from "../screens/admin/matchReminder";
import MatchInsights from "../screens/insights";
import UploadMatchInsights from "../screens/admin/insights";

const Root = () => {
  const { loggedIn } = useUserContext();
  if (loggedIn === null)
    return (
      <div>
        <Header hideButtons />
        <PageLoader />
      </div>
    );
  return (
    <Routes>
      <Route path="/" element={<HomeLogout />} />
      <Route path="/tool/:id" element={<Tool />} />
      <Route path="/login" element={<Login />} />
      <Route path="/genereatedTeams/:matchid?" element={<GenereatedTeams />} />
      <Route path="/matchInsights/:matchid?" element={<MatchInsights />} />
      <Route element={<PrivateRoute />}>
        <Route path="/profile" element={<Profile />} />
        <Route path="/history" element={<History />} />
        <Route path="/history/:matchid" element={<LineupHistory />} />
        <Route path="/teamLayout" element={<TeamLayout />} />
      </Route>
      <Route element={<AdminRoute />}>
        <Route path="/admin" element={<UpcomingMatches />} />
        <Route path="/admin/upload/:id?" element={<UploadMatchData />} />
        <Route path="/admin/insight/:id?" element={<UploadMatchInsights />} />
        <Route
          path="/admin/matchReminder/:matchId"
          element={<MatchReminder />}
        />
      </Route>
    </Routes>
  );
};

export default Root;
